import { defineMessages } from 'react-intl'

const scope = 'app.components.CardEditHeader'

export default defineMessages({
  addFolder: { id: `${scope}.addFolder` },
  cancel: { id: `${scope}.cancel` },
  defaultTheme: { id: `${scope}.defaultTheme` },
  dollarSingInTheme: { id: `${scope}.dollarSingInTheme` },
  folder: { id: `${scope}.folder` },
  msjTheme: {id: `${scope}.msjTheme`},
  place: { id: `${scope}.place` },
  properties: { id: `${scope}.properties` },
  required: { id: `${scope}.required` },
  save: { id: `${scope}.save` },
  secondaryTheme: { id: `${scope}.secondaryTheme` },
  select: { id: `${scope}.select` },
  theme: { id: `${scope}.theme` },
  themeInLocationWarning: { id: `${scope}.themeInLocationWarning` },
  title: { id: `${scope}.title` },
  googleAnalyticsChecked: { id: `${scope}.googleAnalyticsChecked` },
  googleAnalyticsLabel: { id: `${scope}.googleAnalyticsLabel` },
  googleAnalyticsHelp: { id: `${scope}.googleAnalyticsHelp` },
  settings: { id: `${scope}.settings` },
  linkLogo: { id: `${scope}.linkLogo` },
  linkLogoError: { id: `${scope}.linkLogoError` },
  linkLogoInfo: { id: `${scope}.linkLogoInfo` },
  blankLinkLogo: { id: `${scope}.blankLinkLogo` },
  faviconHelpMessage: { id: `${scope}.faviconHelpMessage` },
  seo: { id: `${scope}.seo` },
	seoHelp: { id: `${scope}.seoHelp` },
})

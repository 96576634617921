import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { CardDisplay } from 'components'
import { useIntl } from 'react-intl'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as AppModule } from 'store/app'

import messages from './messages'
import { storeModule as SessionModule } from "../../store/manageUsers";
import { checkCardsAccess } from "../../services/utils";

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const MultipleCardDisplay = ({ app, session, cards, tagId, onMoreClick, maxCards, stateCollapse, headersFilters }) => {

  const intl = useIntl()

  const filteredCards = cards.filter(currentCard =>
    checkCardsAccess(app.id, session.profile, currentCard.tokenId, currentCard.cardId)
  )

  return (
    <InfiniteScroll
      dataLength={maxCards}
      next={onMoreClick}
      hasMore={filteredCards.length >= maxCards}
      loader={<p>{intl.formatMessage(messages.loading)}</p>}
    >
      <StyledContainer>
        {filteredCards.map(
          (currentCard, i) => i < maxCards && (
              <Fragment key={i}>
                <CardDisplay app={app} currentCard={currentCard} appId={app.id} tagId={tagId} stateCollapse={stateCollapse} headersFilters={headersFilters} />
              </Fragment>
            )
        )}
      </StyledContainer>
    </InfiniteScroll>
  )
}

MultipleCardDisplay.defaultProps = {
  cards: [],
  onMoreCards: () => {},
  maxCards: 20,
}

MultipleCardDisplay.propTypes = {
  app: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  cards: PropTypes.array,
  tagId: PropTypes.string,
  onMoreClick: PropTypes.func,
  maxCards: PropTypes.number,
  stateCollapse: PropTypes.string,
	headersFilters: PropTypes.object,
}

export default connectStore({ app: AppModule, session: SessionModule })(MultipleCardDisplay)

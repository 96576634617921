import { Fragment } from 'react'

export const cloneObject = (o) => (o ? JSON.parse(JSON.stringify(o)) : null)

export const newEvent = (id, value) => ({ target: { id, value } })

export const normalizeText = (str) => str.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')

// destined to use in AvInputs validate prop
// ex: validate = {{ pattern: validationsPatterns.positiveInteger }}
export const validationsPatterns = {
  positiveInteger: { value: /^\+?(0|[1-9]\d*)$/ },
  positiveIntegerNoZero: { value: /^\+?([1-9]\d*)$/ },
  oneHashtag: { value: /^#\w+$/ },
  url: {
    value:
      /^((?:http|https):\/\/.*?)+(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/,
  },
  urlExtended: {
    value:
      /^((?:tel|mailto:|#).*?)|^((?:http|https):\/\/(?:[^\s]+?\.[^\s]{2,63}))(\/[-+_~.\w\d%]*)*(\?(&?[-+_~.\w\d%]+=?)*)?(#[-+_~.\w\d%]*)?\s*$/
  },
  youtube: /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\\_]*)(&(amp;)?‌*)?/,
  vimeo:
    /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/,
  noSpaceOrSpecialChar: { value: /^\S+\w/ },
  name: { value: /^[ a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ'`'-]+$/ },
}

export const moveElementInArray = (array, value, positionChange) => {
  const oldIndex = array.indexOf(value)
  if (oldIndex > -1) {
    return moveElementInArrayByPosition(array, oldIndex, positionChange)
  }
  return array
}

export const moveElementInArrayByPosition = (array, oldIndex, positionChange) => {
  if (oldIndex > -1) {
    let newIndex = oldIndex + positionChange

    if (newIndex < 0) {
      newIndex = 0
    } else if (newIndex >= array.length) {
      newIndex = array.length
    }

    const arrayClone = array.slice()
    const value = arrayClone.splice(oldIndex, 1)[0]
    arrayClone.splice(newIndex, 0, value)

    return arrayClone
  }
  return array
}

export const addBrToText = (text = '', key) =>
  text.split('\n').map((v, j) => (
    <Fragment key={`${key}-${j}`}>
      {v}
      <br />
    </Fragment>
  ))

export const orderByUpdateTime = (list) => {
	return list.sort((a, b) => {
		const timeA = a.updateTime ? Number(a.updateTime) : -Infinity;
		const timeB = b.updateTime ? Number(b.updateTime) : -Infinity;
		return timeB - timeA;
	});
}

export const stringToSlug = (str) => {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from =
    'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;'
  const to =
    'AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------'
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}

export const parseHtmlTag = (html) => {
  let e = {}
  try {
    const dom = document.createElement('html')
    dom.innerHTML = html
    const iframe = dom.getElementsByTagName('iframe')[0]
    const data = {
      height: iframe.getAttribute('height'),
      src: iframe.getAttribute('src'),
    }

    Object.keys(data).forEach((key) => {
      e = { target: { id: key, value: data[key] } }
    })
  } catch (error) {
    console.error(error)
  }
  return e
}

export const scrollToError = () => {
  const el = document.getElementsByClassName('is-invalid')
  const modal = document.getElementsByClassName('modal-dialog')

  // if form is in a modal window
  if (modal && modal[0]) {
    const invalidEl = modal[0].getElementsByClassName('is-invalid')
    invalidEl[0].scrollIntoView({
      behavior: 'smooth',
    })
    return
  }

  // if in a simple form page
  if (el && el[0]) {
    const y = el[0].getBoundingClientRect().top + window.scrollY
    window.scroll({
      top: y,
      behavior: 'smooth',
    })
  }
}

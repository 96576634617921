const actions = {
  getUserList: (userId, appId) => ({ type: 'MANAGE_USERLIST_GET', userId, appId }),
  getUserInfo: () => ({ type: 'MANAGE_GET_USER_INFO'}),
  changeAccess: (typeData, value, userId, appId, itemId, tokenId, role, haveAccess, isFolder) => ({ type: 'MANAGE_CHANGE_ACCESS', typeData, value, userId, appId, itemId, tokenId, role, haveAccess, isFolder }),
  saveUser: (user, isShare) => ({ type: 'MANAGE_USER_SAVE_REQUEST', user, isShare }),
  updateUserRights: (appId, user, options) => ({ type: 'MANAGE_USER_UPDATE_RIGHTS', appId, user, options}),
  deleteUserRights: (appId, user, options) => ({ type: 'MANAGE_USER_DELETE_RIGHTS', appId, user, options}),
  disableUser: (user, appId) => ({ type: 'MANAGE_USER_DISABLE', user, appId }),
  resetUserStatus: () => ({ type: 'MANAGE_USERS_RESET_STATUS' }),
  getApps: (uid) => ({ type: 'MANAGE_SESSION_FETCH_APPS', uid }),
  login: (email, password) => ({ type: 'MANAGE_SESSION_LOGIN', email, password }),
  logout: () => ({ type: 'MANAGE_SESSION_LOGOUT' }),
  resetPassword: (code, password) => ({ type: 'MANAGE_SESSION_RESET_PASSWORD', code, password }),
  resetStatus: () => ({ type: 'MANAGE_SESSION_RESET_STATUS' }),
  sendResetPasswordEmail: (email) => ({ type: 'MANAGE_SESSION_SEND_EMAIL', email }),
  setLoggedIn: () => ({ type: 'MANAGE_SESSION_SET_LOGGED_IN' }),
  deleteDisabledUsers: (userId, appId) => ({ type: 'DELETE_DISABLED_USERS', userId, appId }),
  listEnabledUsers: () => ({ type: 'LIST_ENABLED_USERS' }),
}
export default actions

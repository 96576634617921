import { defineMessages } from 'react-intl'

const scope = 'app.components.CardDisplay.parts'

export default defineMessages({Lock: { id: `${scope}.Lock` },
  LockCancel: { id: `${scope}.LockCancel` },
  LockPage: { id: `${scope}.LockPage` },
  photobooth: { id: `${scope}.photobooth` },
  random: { id: `${scope}.random` },
  redirect: { id: `${scope}.redirect` },
  videoInfo: { id: `${scope}.videoInfo` },
  viewLock: { id: `${scope}.viewLock` },
  viewDispatcher: { id: `${scope}.viewDispatcher` },
  randomStoryReset: { id: `${scope}.randomStoryReset` },
  RandomStory: { id: `${scope}.RandomStory` },
  RandomStoryReset: { id: `${scope}.RandomStoryReset` },
  beginLink: { id: `${scope}.beginLink` },
  randomLinks: { id: `${scope}.randomLinks` },
  limit: { id: `${scope}.limit` },
  endLink: { id: `${scope}.endLink` },
  endOption: { id: `${scope}.endOption` },
  endOptionRestart: { id: `${scope}.endOptionRestart` },
  endOptionRedirect: { id: `${scope}.endOptionRedirect` },
  endOptionRedirectLink: { id: `${scope}.endOptionRedirectLink` },
  noLink: { id: `${scope}.noLink` },
})

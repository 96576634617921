import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`

const StyledFramed = styled.div`
  background-color: #34b076;
  border-radius: ${({$borderRadius}) => `${$borderRadius}px`};
  color: white;
  font-weight: bold;

  p {
    margin: 0!important;
    padding: 20px;
    line-height: 20px;
  }
`

const StyledFramedOutlined = styled.div`
  border: ${({$border}) => `${$border}px solid #34b076`};
  border-radius: ${({$borderRadius}) => `${$borderRadius}px`};
  color: black;
  font-weight: bold;
  
  p {
    margin: 0!important;
    padding: 20px;
    line-height: 20px;
  }
`

const Framed = ({ part, partIndex, cardId }) => {

  const {
    style: {
      alternate = false,
      borderRadius = 0,
      borderWidth = 0,
    } = {}
  } = part || {};

  return (
    <StyledWrapper key={`${cardId}-txt-${partIndex}`}>
      {alternate ? (
        <StyledFramedOutlined
          $border={borderWidth}
          $borderRadius={borderRadius}
          dangerouslySetInnerHTML={{ __html: part.text }}
        />
      ) : (
        <StyledFramed
          $borderRadius={borderRadius}
          dangerouslySetInnerHTML={{ __html: part.text }}
        />
      )}

    </StyledWrapper>
  )
}

Framed.propTypes = {
  part: PropTypes.object,
  partIndex: PropTypes.number,
  cardId: PropTypes.string,
}

export default Framed

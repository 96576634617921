import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.FramedForm'

export default defineMessages({
	info: { id: `${scope}.info` },
  text: { id: `${scope}.text` },
  textHelp: { id: `${scope}.textHelp` },
  required: { id: `${scope}.required` },
  alternate: { id: `${scope}.alternate` },
  alternateHelp: { id: `${scope}.alternateHelp` },
  BorderRadiusLabel: { id: `${scope}.BorderRadiusLabel` },
  BorderRadiusHelp: { id: `${scope}.BorderRadiusHelp` },
  BorderRadius0: { id: `${scope}.BorderRadius0` },
  BorderRadius10: { id: `${scope}.BorderRadius10` },
  BorderRadius25: { id: `${scope}.BorderRadius25` },
  BorderRadius50: { id: `${scope}.BorderRadius50` },
  BorderWidthLabel: { id: `${scope}.BorderWidthLabel` },
  BorderWidthHelp: { id: `${scope}.BorderWidthHelp` },
  BorderWidth0: { id: `${scope}.BorderWidth0` },
  BorderWidth1: { id: `${scope}.BorderWidth1` },
  BorderWidth2: { id: `${scope}.BorderWidth2` },
  BorderWidth3: { id: `${scope}.BorderWidth3` },
  justify: { id: `${scope}.justify` },
})

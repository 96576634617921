const state = {
  redirectService: {
    limit: 0,
  },
  numberCustomDomain: 0,
  favicon: '',
  appList: [],
  listDisabledApps: [],
  getAppInfo: {
    error: false,
    fetching: true,
  },
  createApp: {
    fetching: false,
    error: false,
    success: false,
    badEmail: false,
  },
  apps: {
    fetching: false,
    error: false,
    list: [],
  },
  appInfo: {
    userList: [],
  },

}

export default state

const mutations = {
  DISPATCHER_SAVE_ERROR: (state) => {
    state.error = true
    state.saving = false
    state.success = false
  },
  DISPATCHER_SAVE_SUCCESS: (state) => {
    state.error = false
    state.saving = false
    state.success = true
  },
  DISPATCHER_SAVING: (state) => {
    state.error = false
    state.saving = true
    state.success = false
  },
  DISPATCHER_STATUS_RESET: (state) => {
    state.deleted = false
    state.deleting = false
    state.error = false
    state.saving = false
    state.success = false
  },
  DISPATCHER_DELETING: (state) => {
    state.deleted = false
    state.deleting = true
    state.error = false
  },
  DISPATCHER_DELETE_SUCCESS: (state) => {
    state.deleted = true
    state.deleting = false
    state.error = false
  },
  DISPATCHER_GET_LIST: (state) => {
    state.fetching = true
    state.error = false
    state.success = false
  },
  DISPATCHER_SET_LIST: (state, { data }) => {
    state.list = data || {}

    state.fetching = false
    state.error = false
    state.success = false
  },
  DISPATCHER_ADD_TO_LIST: (state, { data }) => {
    state.list[data.id] = data

    state.error = false
    state.success = false
  },
  DISPATCHER_DELETE_FROM_LIST: (state, { id }) => {
    const list = { ...state.list }
    delete list[id]
    state.list = list

    state.error = false
  },
  DISPATCHER_ERROR: (state) => {
    state.error = true
  },
}
export default mutations

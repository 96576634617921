import styled from 'styled-components'
import { CardBody, Collapse } from 'reactstrap'
import { Icon } from 'react-icons-kit'

export const StyledCollapse = styled(Collapse)`
  background-color: #12263f;
  border-radius: 8px 8px 0 0;
  margin-bottom: 0 !important;

  label {
    color: #c8cfd9 !important;
    font-weight: 600;

    i {
      margin-right: 5px;
    }
  }

  select,
  select:is(:focus, :active) {
    width: 100% !important;
    border-radius: 0 !important;
    border: none;
    border-bottom: 1px solid #c8cfd9 !important;
    background-color: #12263f !important;
    color: #c8cfd9 !important;
  }
`

export const StyledSelect = styled.div`
  margin-top: -10px;

  .search-wrapper {
    display: bloc;
    border: none !important;
    padding: 0 !important;
    padding-top: 10px !important;
  }

  .search-wrapper,
  input:not(.searchBox),
  input:not(.searchBox):is(:focus, :active) {
    display: inline-flex;
    align-items: baseline;
    width: 100% !important;
    border-radius: 0 !important;
    border: none;
    border-bottom: 1px solid #c8cfd9 !important;
    background-color: #12263f !important;
    color: #c8cfd9 !important;
  }

  input {
    margin-bottom: 5px;
    padding-left: 10px;
  }

  #folder_input {
    border: none;
    border-color: transparent;
  }

  .chip {
    color: #c8cfd9 !important;
    border-color: #c8cfd9 !important;
  }

  .icon_cancel {
    color: white;
  }
`

export const StyledTitle = styled.div`
  color: #89959f;
  text-transform: uppercase;
  font-weight: 500;
  padding-top: 5px;
`

export const StyledHeaderContainer = styled(CardBody)`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 0;
`

export const StyledCloseIcon = styled(Icon)`
  color: #89959f;
  border: 2px solid #89959f;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  &:hover:not(:disabled) {
    color: #12263f;
    background-color: #89959f;
    border: 1px solid #89959f;
    cursor: pointer;
  }

  svg {
    width: 8px;
    height: 8px;
  }
`


const getDispatcherById = ({ list }, id) => Object.values(list).find((i) => i.id === id)

const getDispatcherByName = ({ list }, name) => Object.values(list).filter((l) => l.name === name)

const selectors = {
  getDispatcherById: (state) => (id) => getDispatcherById(state, id),
  getDispatcherByName: (state) => (name) => getDispatcherByName(state, name),
  dispatcher: (state) => (id) => state.list[id] || {},
}
export default selectors

import PropTypes from 'prop-types'
import { useState } from 'react'
import { Col } from 'reactstrap'
import { useIntl } from 'react-intl'
import { BasicInput, BasicInfo, BasicFormSection } from 'components'
import { validationsPatterns } from 'utils'

import { connectStore } from 'redux-box'
import { storeModule as ModuleModules } from 'store/modules'

import messages from '../messages'
import { StyledLegend } from './styles'
import EmailTrigger from './EmailTrigger'

const GeneralInfo = ({ modules, data, id }) => {
  const [triggerType, setTriggerType] = useState(data ? data.type : null)

  const intl = useIntl()

  const handleValidation = (value) => {
    const triggers = modules.getModuleByName('trigger', value) || []
    const differentId = triggers.filter((t) => t.id !== id)
    return differentId.length <= 0
  }

  return (
    <>
      <StyledLegend>{intl.formatMessage(messages.generalInfo)}</StyledLegend>

      <BasicInput name="id" type="hidden" />
      <BasicInput type="hidden" name="style.displayType" value="Button" />
      <BasicInput type="hidden" name="onSuccess.action" value="redirect" />

      <BasicInput
        name="name"
        label={intl.formatMessage(messages.name)}
        helpMessage={intl.formatMessage(messages.nameHelp)}
        error={intl.formatMessage(messages.nameError)}
        required
        validation={{ async: (value) => handleValidation(value) }}
      />

      <hr style={{ margin: '20px 0 30px 0' }} />

      <StyledLegend>{intl.formatMessage(messages.buttonParams)}</StyledLegend>

      <div style={{ margin: '20px 0' }}>
        <BasicInfo text={intl.formatMessage(messages.buttonInfo)} />
      </div>

      <BasicInput
        name="style.text"
        label={intl.formatMessage(messages.buttonText)}
        error={intl.formatMessage(messages.buttonTextError)}
        required
      />

      <BasicInput
        name="type"
        type="select"
        label={intl.formatMessage(messages.type)}
        helpMessage={intl.formatMessage(messages.typeHelp)}
        error={intl.formatMessage(messages.typeError)}
        onChange={(e) => setTriggerType(e.target.value)}
        required
      >
        <option value="" disabled>
          {intl.formatMessage(messages.selectTrigger)}
        </option>
        <option value="email">{intl.formatMessage(messages.email)}</option>
        <option value="redirect">{intl.formatMessage(messages.redirect)}</option>
      </BasicInput>

      <BasicFormSection>
        <Col>
          {triggerType === 'email' ? (
            <EmailTrigger />
          ) : (
            <StyledLegend>{intl.formatMessage(messages.redirect)}</StyledLegend>
          )}

          <BasicInput
            name="onSuccess.params.url"
            label={intl.formatMessage(messages.redirectUrl)}
            helpMessage={intl.formatMessage(messages.redirectUrlHelp)}
            error={intl.formatMessage(messages.redirectUrlError)}
            required
            validation={{ pattern: validationsPatterns.url }}
            link={data ? data.onSuccess.params.url : ''}
          />
        </Col>
      </BasicFormSection>
    </>
  )
}

GeneralInfo.propTypes = {
  data: PropTypes.any,
  id: PropTypes.string,
  modules: PropTypes.object,
}

export default connectStore({ modules: ModuleModules })(GeneralInfo)

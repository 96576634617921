import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Input } from 'reactstrap'
import { useIntl } from 'react-intl'

import { StyledSelect } from './styles'
import messages from './messages'
import Headers from './headers'

const Collapse = ({ stateCollapse, changeCollapse, changeHeaders, headers }) => {
  const intl = useIntl()

  const handleOnChange = (e) => {
    changeCollapse(e.target.value);
  }

  return (
    <StyledSelect>
      <label>{intl.formatMessage(messages.view)}</label>

	    <Headers headers={headers} changeHeaders={changeHeaders} />

      <Input type="select" name="collapse" value={stateCollapse} onChange={handleOnChange}>
        <option value="collapse">{intl.formatMessage(messages.collapse)}</option>
        <option value="expand">{intl.formatMessage(messages.expand)}</option>
      </Input>
    </StyledSelect>
  )
}

Collapse.propTypes = {
  stateCollapse: PropTypes.string,
  changeCollapse: PropTypes.func,
	changeHeaders: PropTypes.func,
	headers: PropTypes.object,
}

export default withRouter(Collapse)

import { defineMessages } from 'react-intl'

const scope = 'app.components.CardFilters'

export default defineMessages({
  addUnlinkedCard: { id: `${scope}.addUnlinkedCard` },
  all: { id: `${scope}.all` },
  collapse: { id: `${scope}.collapse` },
  expand: { id: `${scope}.expand` },
  linkedCards: { id: `${scope}.linkedCards` },
  show: { id: `${scope}.show` },
  unlinkedCards: { id: `${scope}.unlinkedCards` },
  view: { id: `${scope}.view` },
	headersProject: { id: `${scope}.headersProject` },
	headersTheme: { id: `${scope}.headersTheme` },
	headersGaTitle: { id: `${scope}.headersGaTitle` },
	headersLanguage: { id: `${scope}.headersLanguage` },
	headersLogo: { id: `${scope}.headersLogo` },
	headersReset: { id: `${scope}.headersReset` },
})

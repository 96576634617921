import PropTypes from 'prop-types'
import { StyledContainer, StyledLabel } from './styles'
import { Icon } from 'react-icons-kit'
import { externalLink } from 'react-icons-kit/feather'

const BasicLink = ({ link, margin }) => {

  return <StyledContainer $margin={margin}>
    <StyledLabel href={link} target="_blank">
	    <Icon icon={externalLink} />
    </StyledLabel>
  </StyledContainer>
}

BasicLink.propTypes = {
  link: PropTypes.string,
  margin: PropTypes.number,
}

export default BasicLink

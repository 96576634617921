import { BasicButton, BasicModal } from "components";
import messages from './message';
import { useIntl } from 'react-intl';

import { connectStore } from 'redux-box';
import { storeModule as SessionModule, storeModule as ManageUsersModule } from 'store/manageUsers'
import { storeModule as ManageAppsModule } from 'store/manageApps'
import { storeModule as ManageCardModule } from 'store/card'
import { storeModule as ManageAppModule } from 'store/app'
import { storeModule as ManageCardListModule } from 'store/cardList'

import PropTypes from 'prop-types';
import { StyledModal, StyledHeader, StyledSubtitle, StyledPublicLink } from "./styles";
import { useEffect, useState } from 'react';

import {
  StyledCopyLink,
  StyledHelpMessage,
  StyledPublicLinkContent,
  StyledUsersContainers
} from "../ShareModal/styles";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { Icon } from "react-icons-kit";
import { copy } from 'react-icons-kit/feather/copy'
import UsersShareFolder from "./users";
import { ADMIN_SHORT_URL } from "../../utils/consts";
import { checkAccessDates } from "../../utils/share";

const ShareModalFolders = ({openModal, setOpenModal, manageApps, appId, folderId, folderTitle, session }) => {
  const intl = useIntl();
  const [usersOfApp, setUsersOfApp] = useState([]);
  const [copyLink, setCopyLink] = useState(false);
  const dns = `${ADMIN_SHORT_URL}/${appId}/folders`;

  useEffect(() => {
    const updatedUsers = manageApps.appInfo.userList
      .filter(user => !user.superadmin)
      .filter(user => {
        return !user.apps.some(app =>
	        app.appId === appId &&
          app.roles.some(role => role.role === "admin" || role.role === "owner" || role.role === "superowner")
        );
      })
      .map(user => {
      const hasAccess = user.apps.some(app => {
        if (app.appId === appId && app.roles.length === 1) {
          const role = app.roles[0];
          let dateValid = checkAccessDates(role);

          switch (role.typeAccess) {
            case "allApp":
              return dateValid;
            case "none_exept":
              return dateValid && role.access.folders.some(folderAccess => {
                return folderAccess.folderId === folderId && checkAccessDates(folderAccess);
              });
            default:
              return false;
          }
        }
        return false;
      });
      return {...user, hasAccess};
    });

    setUsersOfApp(updatedUsers);
  }, [manageApps.appInfo, appId, folderId]);

  const handleClickCopyLink = () => {
    setCopyLink(true);

    setTimeout(() => {
      setCopyLink(false);
    }, 5000)
  }

  return <BasicModal
    isOpen={openModal}
    toggle={() => setOpenModal(!openModal)}
    share
    content={
      <StyledModal>
        <StyledPublicLink>
          <p>{intl.formatMessage(messages.linkViewFolder)}</p>
          <div className="linkContent">
            <StyledPublicLinkContent>{`${dns}/${folderId}/all`}</StyledPublicLinkContent>
            <CopyToClipboard text={`${dns}/${folderId}/all`} onCopy={handleClickCopyLink}>
              <BasicButton icon={<Icon icon={copy} />} small outlined round />
            </CopyToClipboard>
          </div>
          {copyLink && <StyledCopyLink>{intl.formatMessage(messages.copyLinkViewFolder)}</StyledCopyLink>}
        </StyledPublicLink>
        {(session.profile.superadmin || (session.profile.role !== 'editor' && session.profile.role !== 'redactor')) && <StyledUsersContainers>
          <StyledHeader><h5>{intl.formatMessage(messages.share)}</h5></StyledHeader>

          {usersOfApp.length > 0 && <>
            <StyledSubtitle>{intl.formatMessage(messages.userHasAccess)}</StyledSubtitle>
            <StyledHelpMessage>{intl.formatMessage(messages.helpSectionUsers)}</StyledHelpMessage>
            {usersOfApp.map(
              (user, index) =>
                <UsersShareFolder
                  key={index}
                  index={index}
                  user={user}
                  appId={appId}
                  folderId={folderId}
                />
            )}
          </>}
        </StyledUsersContainers>}
      </StyledModal>
    }
    title={`${intl.formatMessage(messages.accessSubtitle)} - ${folderTitle}`}
  />;
};

ShareModalFolders.propTypes = {
  folderId: PropTypes.string,
  manageUsers: PropTypes.object.isRequired,
  manageApps: PropTypes.object.isRequired,
  manageCard: PropTypes.object.isRequired,
  manageCardList: PropTypes.object.isRequired,
  manageApp: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  folderTitle: PropTypes.string,
};

export default connectStore({
  manageUsers: ManageUsersModule,
  manageApps: ManageAppsModule,
  manageCard: ManageCardModule,
  manageCardList: ManageCardListModule,
  manageApp: ManageAppModule,
  session: SessionModule
})(ShareModalFolders);


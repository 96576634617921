import React, { useState, useEffect } from 'react'
import { BasicButton, BasicInput, BasicModal } from 'components'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { primitiveDot } from 'react-icons-kit/oct/primitiveDot'
import { cornerDownRight } from 'react-icons-kit/feather/cornerDownRight'
import { trash } from 'react-icons-kit/feather/trash'
import { Icon } from 'react-icons-kit'
import { Fieldset, StyledLegend, StyledBasicInput, StyledBasicButton, StyledSpanStatus, StyledArrow } from './styles'

import msjSurpris from 'assets/img/MSJ-Surpris.png'

import messages from './messages'

const BasicInputArrayRedirectService = ({ app, onDataChange }) => {
  const [domains, setDomains] = useState(app?.redirectService?.domains || [])
  const [newDomain, setNewDomain] = useState('')
  const [limitDomain, setLimitDomain] = useState(0)
  const [newDomainError, setNewDomainError] = useState(false)

  const [openModalDomain, setOpenModalDomain] = useState(false)
  const [confirmDeleteDomain, setConfirmDeleteDomain] = useState('')

  const [rewrites, setRewrites] = useState(app?.redirectService?.rewrites || [])
  const [newRewrite, setNewRewrite] = useState({})
  const [newRewriteError, setNewRewriteError] = useState(false)

  const intl = useIntl()

  useEffect(() => {
    onDataChange({ domains: domains, rewrites: rewrites })
    if(app.redirectService && app.redirectService.limit) {
      setLimitDomain(app.redirectService.limit)
    }
  }, [domains, rewrites])

  const toggleModalDomain = (domain) => {
    setOpenModalDomain(!openModalDomain)
    setConfirmDeleteDomain(domain)
  }

  const handleOnKeyDown = (e) => {
    if (e.key === 'Escape' && openModalDomain) {
      setOpenModalDomain(!openModalDomain)
    }
  }

  const handleDomainAdd = () => {
    if (newDomain && !domains.some(domainObj => domainObj.domain === newDomain)) {
      setDomains([...domains, { domain: newDomain, status: 'pending' }]);
      setNewDomain('');
      setNewDomainError(false);
    } else {
      setNewDomainError(true);
    }
  };

  const handleDomainUpdate = (domain, newDomainValue) => {
    setDomains(prevDomains =>
      prevDomains.map(url =>
        url.domain === domain ? { ...url, domain: newDomainValue } : url
      )
    );
  };

  const handleDomainDelete = () => {
    setDomains(domains.filter((url) => url.domain !== confirmDeleteDomain))

    setRewrites(rewrites.filter((url) => url.domain !== confirmDeleteDomain))

    toggleModalDomain()
  }

  const handleRewriteAdd = () => {
    if (newRewrite.domain && newRewrite.from && newRewrite.to) {
      setRewrites([...rewrites, newRewrite])
      setNewRewrite({ domain: '', from: '', to: '' })
      setNewRewriteError(false)
    } else {
      setNewRewriteError(true)
    }
  }

  const handleRewriteUpdate = (index, field, value) => {
    setRewrites(prevRewrites =>
      prevRewrites.map((rewrite, i) =>
        i === index ? { ...rewrite, [field]: value } : rewrite
      )
    );
  };

  const handleRewriteDelete = (domain, from, to) => {
    setRewrites(
      rewrites.filter((url) => !(url.domain === domain && url.from === from && url.to === to)
    ))
  }

  return (
    <>
      <div className="mt-2">
        <StyledLegend>{intl.formatMessage(messages.enterYourDomain)}</StyledLegend>

        <Fieldset>
          {newDomainError && (
            <span className="text-danger" style={{ gridColumn: 'span 12' }}>
               {intl.formatMessage(messages.domainErrorValid)}
            </span>
          )}

          {(domains.length === 0 || domains.length < limitDomain) &&  <StyledBasicInput $gridColumn={10}>
            <BasicInput
              type="text"
              name="domains"
              placeholder="mysmartjourney.com"
              value={newDomain}
              helpmessage={intl.formatMessage(messages.enterNameYouBought)}
              onChange={(e) => setNewDomain(e.target.value)}
            />
          </StyledBasicInput>}

          <StyledBasicInput $gridColumn={2} />
        </Fieldset>

        {domains?.map((url) => (
          <Fieldset key={`domain-loop-${url.domain}`}>

            <StyledBasicInput $gridColumn={10}>
              <BasicInput
                type="text"
                name="domains"
                value={url.domain}
                onChange={(e) => handleDomainUpdate(url.domain, e.target.value)}
              />
              <BasicButton onClick={() => toggleModalDomain(url.domain)} icon={<Icon icon={trash} />} outlined round />
            </StyledBasicInput>

            {url.status === 'pending' && (
              <StyledSpanStatus $color={'#F49F37'} $gridColumn={'span 2'}>
                <Icon icon={primitiveDot} /> {intl.formatMessage(messages.pending)}
              </StyledSpanStatus>
            )}

            {url.status === 'valid' && (
              <StyledSpanStatus $color={'#34b076'} $gridColumn={'span 2'}>
                <Icon icon={primitiveDot} /> {intl.formatMessage(messages.connected)}
              </StyledSpanStatus>
            )}

            {url.status === 'invalid' && (
              <StyledSpanStatus $color={'red'} $gridColumn={'span 2'}>
                <Icon icon={primitiveDot} /> {intl.formatMessage(messages.failed)}
              </StyledSpanStatus>
            )}
          </Fieldset>
        ))}

        <Fieldset>
          <StyledBasicButton>
            <BasicButton
              color="green"
              medium
              onClick={handleDomainAdd}
              label={intl.formatMessage(messages.addThisDomain)}
              icon=""
              disabled={domains.length >= limitDomain && domains.length > 0}
            />
          </StyledBasicButton>
        </Fieldset>
      </div>

      {domains.length > 0 && (
        <>
          <hr />

          <Fieldset className="mt-2 small">
            <StyledLegend>{intl.formatMessage(messages.customizeYourUrl)}</StyledLegend>

            {newRewriteError && (
              <span className="text-danger" style={{ gridColumn: 'span 12' }}>
                {intl.formatMessage(messages.pleaseFillAllInput)}
              </span>
            )}

            <StyledBasicInput $gridColumn={4}>
              <BasicInput
                type="select"
                name="rewrite.domain"
                value={newRewrite?.domain}
                onChange={(e) => setNewRewrite({ ...newRewrite, domain: e.target.value })}
              >
                <option value="">

                </option>
                {domains?.map((url) => (
                  <option key={url.domain} value={url.domain}>
                    {url.domain}
                  </option>
                ))}
              </BasicInput>
            </StyledBasicInput>

            <StyledBasicInput $gridColumn={6}>
              <BasicInput
                type="text"
                name="rewrite.from"
                placeholder="/"
                value={newRewrite?.from}
                onChange={(e) => setNewRewrite({ ...newRewrite, from: e.target.value })}
              />
            </StyledBasicInput>

            <StyledBasicInput $gridColumn={2} />

            {/* New row */}

            <StyledArrow $gridColumn={4} $color={'#707e8a'}>
              <Icon icon={cornerDownRight} size={30} />
            </StyledArrow>

            <StyledBasicInput $gridColumn={6}>
              <BasicInput
                type="text"
                name="rewrite.to"
                placeholder="/"
                label={intl.formatMessage(messages.enterUrlToRedirect)}
                value={newRewrite?.to}
                onChange={(e) => setNewRewrite({ ...newRewrite, to: e.target.value })}
              />
            </StyledBasicInput>

            <StyledBasicInput $gridColumn={2} />
          </Fieldset>

          {rewrites?.map((url, i) => (
            <Fieldset key={`rewrite-form-loop-${i}`}>
              <StyledBasicInput $gridColumn={4}>
                <BasicInput
                  type="select"
                  name="rewrite.domain"
                  value={url.domain}
                >
                  <option value="{url.domain}">
                    {url.domain}
                  </option>
                </BasicInput>
              </StyledBasicInput>

              <StyledBasicInput $gridColumn={6}>
                <BasicInput
                  type="text"
                  name="rewrite.from"
                  placeholder="/"
                  value={url.from}
                  onChange={(e) => handleRewriteUpdate(i, 'from', e.target.value)}
                />
                <BasicButton onClick={() => {
                  handleRewriteDelete(url.domain, url.from, url.to)
                }} icon={<Icon icon={trash} />} outlined round />
              </StyledBasicInput>

              <StyledBasicInput $gridColumn={2} />

              {/* New row */}

              <StyledArrow $gridColumn={4} $color={'#707e8a'}>
                <Icon icon={cornerDownRight} size={30} />
              </StyledArrow>

              <StyledBasicInput $gridColumn={6}>
                <BasicInput
                  name="rewrite.to"
                  type="text"
                  placeholder="/"
                  label={intl.formatMessage(messages.enterUrlToRedirect)}
                  value={url.to}
                  onChange={(e) => handleRewriteUpdate(i, 'to', e.target.value)}
                />
              </StyledBasicInput>

              <StyledBasicInput $gridColumn={2} />
            </Fieldset>
          ))}

          <Fieldset>
            <StyledBasicInput>
              <BasicButton
                color="green"
                medium
                onClick={handleRewriteAdd}
                label={intl.formatMessage(messages.addThisRedirection)}
                icon=""
              />
            </StyledBasicInput>
          </Fieldset>

          {openModalDomain && (
            <>
              <BasicModal
                isOpen={openModalDomain}
                toggle={toggleModalDomain}
                onKeyDown={handleOnKeyDown}
                size="small"
                close={false}
                title=""
                content={
                  <>
                    <div className={'d-flex flex-row justify-content-between align-items-center'}>
                      <img src={msjSurpris} alt="emoji" width={125} height={125} />
                      <div className={'ml-3 d-flex flex-column'}>
                        <p className={'mb-0 font-weight-bold'}>{intl.formatMessage(messages.confirmDeleteDomain)} <span className={'text-danger'}>{confirmDeleteDomain}</span></p>
                        <p className={'mb-0 mt-3 small text-danger'}>{intl.formatMessage(messages.warningBeforeDeleteDomain)}</p>
                      </div>
                    </div>
                  </>
                }
                actions={
                  <>
                    <BasicButton onClick={toggleModalDomain} label={intl.formatMessage(messages.cancel)} outlined />
                    <BasicButton onClick={handleDomainDelete} label={intl.formatMessage(messages.deleteThisDomain)} color="red" />
                  </>
                }
              />
            </>
          )}
        </>
      )}
    </>
  )
}

BasicInputArrayRedirectService.defaultProps = {
  onDataChange: () => {}
}

BasicInputArrayRedirectService.propTypes = {
  app: PropTypes.object,
  onDataChange: PropTypes.func,
}

export default BasicInputArrayRedirectService

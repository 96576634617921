import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { Collapse, Nav, NavbarToggler, NavLink } from 'reactstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { rteAppId } from 'utils/routes'
import msjLogo from 'assets/img/logo-blanc-big.svg'

import AppDropdown from './Dropdowns/AppDropdown'
import UserDropdown from './Dropdowns/UserDropdown'
import LanguageToggle from './Dropdowns/LanguageToggle'

import { getNavLinks, isLinkActive, getModuleLinks, getManageLinks } from './helpers'
import {
  StyledDiv,
  StyledDiv2,
  StyledNavbar,
  StyledNavHeader,
  StyledNavItem,
  StyledNavSeparation,
} from './styles'
import messages from './messages'
import { checkGlobalAccess } from "../../services/utils";

const NavBar = ({
  app,
  appId,
  appName,
  location: { pathname },
  changeApp,
  superadmin,
  userRoles,
  user,
  match: {
    params: { folderId },
  },
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const intl = useIntl()

  const toggleNav = () => setIsOpen(!isOpen)

  const navlink = userRoles && getNavLinks(app, intl.formatMessage(messages.all), appId, folderId, true, userRoles, superadmin, toggleNav)

  const moduleLink = getModuleLinks(superadmin, app)

  const manageLink = userRoles && getManageLinks(appId, userRoles, superadmin)

  const seeSectionManage = userRoles && checkGlobalAccess(appId, superadmin, userRoles, 'SeeSectionManage');

  return (
    <StyledNavbar dark expand="md">
      <StyledNavHeader>
        <AppDropdown
          appName={appName}
          onChangeApp={(value) => {
            if (isOpen) toggleNav()
            changeApp(value)
          }}
          className="d-md-none"
        />

        <img src={msjLogo} alt="MySmartJourney" style={{ marginLeft: '20px' }} />

        <StyledDiv>
          <UserDropdown appId={appId} user={user} />
          <LanguageToggle />
        </StyledDiv>

        <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
      </StyledNavHeader>

      <Collapse isOpen={isOpen} navbar>
        <Nav navbar>
          {navlink.map((link, i) => link.hasAccess && (
            <Fragment key={i}>
              <StyledNavItem
                key={i}
                selected={isLinkActive(pathname, link.route, appId)}
                className="d-md-none"
              >
                <NavLink tag={Link} to={rteAppId(appId, link.route)} onClick={toggleNav}>
                  {link.icon}
                  <FormattedMessage {...messages[link.message]} />
                </NavLink>

                {link.button && link.button}
              </StyledNavItem>
              {link.children && link.children}
            </Fragment>
          ))}

          <StyledNavSeparation className="d-md-none">
            <FormattedMessage {...messages.modules} />
          </StyledNavSeparation>

          {moduleLink.map((link, i) => (
            <Fragment key={i}>
              {link.hasAccess && (
                <StyledNavItem
                  selected={isLinkActive(pathname, link.route, appId)}
                  className="d-md-none"
                  type="module"
                >
                  <NavLink tag={Link} to={rteAppId(appId, link.route)} onClick={toggleNav}>
                    <FormattedMessage {...messages[link.message]} />
                  </NavLink>
                </StyledNavItem>
              )}
            </Fragment>
          ))}

          {seeSectionManage && (
            <StyledNavSeparation className="d-md-none">
              <FormattedMessage {...messages.manage} />
            </StyledNavSeparation>
          )}

          {manageLink.map((link, i) => (
            <Fragment key={i}>
              {link.hasAccess && (
                <StyledNavItem selected={isLinkActive(pathname, link.route, appId)} className="d-md-none">
                  <NavLink tag={Link} to={rteAppId(appId, link.route)} onClick={toggleNav}>
                    <FormattedMessage {...messages[link.message]} />
                  </NavLink>
                </StyledNavItem>
              )}
            </Fragment>
          ))}

          <StyledNavSeparation className="d-md-none">
            <FormattedMessage {...messages.yourAccount} />
          </StyledNavSeparation>

          <StyledDiv2>
            <UserDropdown appId={appId} user={user} onClick={toggleNav} isMobile />

            <LanguageToggle onClick={toggleNav} isMobile />
          </StyledDiv2>
        </Nav>
      </Collapse>
    </StyledNavbar>
  )
}

NavBar.defaultProps = {
  appName: 'App name',
  changeApp: () => {},
  user: '',
}

NavBar.propTypes = {
  app: PropTypes.object,
  appId: PropTypes.string,
  appName: PropTypes.string,
  changeApp: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.object,
  superadmin: PropTypes.bool,
  userRoles: PropTypes.array,
  user: PropTypes.string,
}

export default withRouter(NavBar)

import styled from 'styled-components'

export const StyledButtonHalf = styled.a`
  display: block;
  background-size: cover;
  background-position: center center;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: ${({ borderRadius }) => borderRadius}%;
  border: ${({ borderWidth }) => borderWidth}px solid black;
  width: 100%;
  background-image: url(${({ url }) => url !== '' ? url : ''});
    

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`

export const StyledHalfContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  line-height: 10px;
  margin: 10px 0;
`

const getFlex = (columns) => {
  switch (columns) {
    case '3':
      return '33%'
    case '4':
      return '25%'
    default:
      return '43%'
  }
}

const getFontSize = (columns) => {
  switch (columns) {
    case '3':
      return '13px'
    case '4':
      return '12px'
    default:
      return '15px'
  }
}

export const StyledContainerItem = styled.div`
  flex-basis: ${({ columns }) => getFlex(columns)};
  max-width: ${({ columns }) => getFlex(columns)};
  padding: 10px;
  text-align: center;

  span,
  a {
    font-weight: 600;
    font-size: ${({ columns }) => getFontSize(columns)};
    word-break: break-word;
    color: black;

    &:hover {
      text-decoration: none;
    }
  }
`

import styled from 'styled-components'

export const StyledContainer = styled.div`
  margin-left: 10px;
  margin-top: ${({ $margin }) => `${$margin}px`};
`

export const StyledLabel = styled.a`
    font-family: Nunito,serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    color: #416db2 !important;
`
import styled from 'styled-components'

export const StyledContainer = styled.div`
    padding-top: 10px;
`;

export const StyledInputContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    gap: 1px;
    
    .form-group {
        grid-column: 11 span;
    }
`;
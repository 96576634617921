import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {
  DropZone,
  BasicInfo,
  BasicLabel,
  BasicSlider,
  BasicRadioButtons,
  BasicCheckbox,
  BasicInput,
} from 'components'

import { Icon } from 'react-icons-kit'
import { check } from 'react-icons-kit/feather/check'
import { image } from 'react-icons-kit/feather/image'
import { type } from 'react-icons-kit/feather/type'

import messages from '../messages'
import {
  StyledLegend,
  StyledRow,
  StyledGroupButton,
  StyledPosButton,
  StyledPosPreview,
  StyledFakeWatermark,
  StyledDropzones,
} from './styles'

const positions = [
  'top-left',
  'top-center',
  'top-right',
  'left',
  'center',
  'right',
  'bottom-left',
  'bottom-center',
  'bottom-right',
]

const PhotoboothFrame = ({ photobooth, onPosChange, pos }) => {
  const [showDropdown, setShowDropdown] = useState(true)
  const [watermarkOpac, setWatermarkOpac] = useState(photobooth.watermarkOpacity || 100)
  const [stickerOpt, setStickerOpt] = useState(photobooth.useStickers || 'default')

  /* 
    filter on stickers state because old photobooths 
    saved empty string in stickers array
  */
  const [stickers, setStickers] = useState(
    (photobooth.stickers && photobooth.stickers.filter((s) => s)) || ['']
  )

  const intl = useIntl()

  useEffect(() => {
    if (photobooth.stickers) {
      const copy = [...stickers]
      copy.push('')
      setStickers(copy)
    }
    if (photobooth.noWatermark) {
      setShowDropdown(false)
    }
  }, [])

  const handleAddSticker = (file) => {
    const copy = [...stickers]
    copy.pop()
    copy.push(file[0])
    copy.push('')
    setStickers(copy)
  }

  const handleRemoveSticker = (i) => {
    if (stickers.length === 1) {
      setStickers([''])
      return
    }

    let copy = [...stickers]
    copy.splice(i, 1)
    copy = copy.filter((f) => f)
    copy.push('')
    setStickers(copy)
  }

  const handleSliderChange = (e) => setWatermarkOpac(e.target.value)

  return (
    <>
      <StyledLegend>{intl.formatMessage(messages.pf.title)}</StyledLegend>

      <div style={{ margin: '20px 0' }}>
        <BasicCheckbox
          name="part.noWatermark"
          label={intl.formatMessage(messages.pf.noWatermark)}
          onChange={(e) => setShowDropdown(!e.target.checked)}
        />
      </div>

      {showDropdown && (
        <>
          <DropZone
            name="part.watermarkFile"
            url={photobooth.watermarkUrl || ''}
            label={intl.formatMessage(messages.pf.watermark)}
            helpMessage={intl.formatMessage(messages.pf.watermarkHelp)}
            rejectLabel={intl.formatMessage(messages.pf.watermarkError)}
            accept="image/png, image/x-png, image/svg+xml"
            size="medium"
            type="image"
          />

          <BasicInfo text={intl.formatMessage(messages.pf.watermarkInfo)} />

          <BasicInput
            type="select"
            name="part.watermarkSize"
            label={intl.formatMessage(messages.pf.watermarkSize)}
          >
            <option value="">{intl.formatMessage(messages.pf.small)}</option>
            <option value="medium">{intl.formatMessage(messages.pf.medium)}</option>
            <option value="large">{intl.formatMessage(messages.pf.large)}</option>
          </BasicInput>

          <StyledRow>
            <div style={{ marginRight: '10px' }}>
              <BasicLabel
                label={intl.formatMessage(messages.pf.position)}
                helpMessage={intl.formatMessage(messages.pf.positionHelp)}
              />

              <StyledGroupButton>
                {Object.keys(positions).map((i) => (
                  <StyledPosButton
                    selected={String(i) === String(pos)}
                    key={i}
                    onClick={() => onPosChange(i)}
                    type="button"
                  >
                    {String(i) === String(pos) && <Icon icon={check} />}
                  </StyledPosButton>
                ))}
              </StyledGroupButton>
            </div>

            <div>
              <BasicLabel
                label={intl.formatMessage(messages.pf.preview)}
                helpMessage={intl.formatMessage(messages.pf.previewHelp)}
              />

              <StyledPosPreview>
                <Icon icon={image} />

                <StyledFakeWatermark position={positions[pos]} opacity={String(watermarkOpac)}>
                  <Icon icon={type} />
                  <Icon icon={type} />
                  <Icon icon={type} />
                </StyledFakeWatermark>
              </StyledPosPreview>
            </div>
          </StyledRow>

          <BasicSlider
            name="part.watermarkOpacity"
            label={intl.formatMessage(messages.pf.opacity)}
            helpMessage={intl.formatMessage(messages.pf.opacityHelp)}
            error={intl.formatMessage(messages.pf.opacityError)}
            onChange={handleSliderChange}
            value={watermarkOpac}
          />
        </>
      )}

      <BasicRadioButtons
        name="part.useStickers"
        label={intl.formatMessage(messages.pf.stickers)}
        helpMessage={intl.formatMessage(messages.pf.stickersHelp)}
        error={intl.formatMessage(messages.pf.stickersError)}
        onChange={(e) => setStickerOpt(e.target.value)}
        value={stickerOpt}
        radioButtons={[
          { label: intl.formatMessage(messages.pf.none), value: 'none' },
          { label: intl.formatMessage(messages.pf.default), value: 'default' },
          { label: intl.formatMessage(messages.pf.custom), value: 'custom' },
        ]}
        required
      />

      {stickerOpt === 'custom' && (
        <StyledDropzones>
          {Object.values(stickers).map((sticker, i) => (
            <DropZone
              key={i}
              name={`part.stickers.${i}`}
              file={sticker}
              url={sticker}
              onDrop={handleAddSticker}
              onRemoveFile={() => handleRemoveSticker(i)}
              rejectLabel={intl.formatMessage(messages.pf.stickerFormatError)}
              accept="image/svg+xml"
              size="small"
              type="image"
            />
          ))}
        </StyledDropzones>
      )}
    </>
  )
}

PhotoboothFrame.propTypes = {
  onPosChange: PropTypes.func,
  photobooth: PropTypes.object,
  pos: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default PhotoboothFrame

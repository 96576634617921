import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import {
  StyledInputContainer,
  StyledButton,
  StyledErrorLabel,
  StyledContainer,
  StyledInput,
  StyledGoButton,
  StyledGoInput,
  StyledEmojiButton,
  StyledInputGoEmojiContainer,
} from './styles'

import { Icon } from 'react-icons-kit'
import { lock } from 'react-icons-kit/feather/lock'
import { check } from 'react-icons-kit/feather/check'
import { xCircle } from 'react-icons-kit/feather/xCircle'

const Dispatcher = ({ values }) => {
  const [answer, setAnswer] = useState('')
  const [status, setStatus] = useState('')
  const [typeButton, setTypeButton] = useState('')

	useEffect(() => {
		if (values) {
			setTypeButton(detectStringCase(values.display.textButton))
		}
	}, [values]);

	const detectStringCase = (input) => {
		const goRegex = /^<p>(Go !|Go!|go !|go!|GO !|GO!|)<\/p>$/

	  if (goRegex.test(input)) {
			return 'go'
		}

		return isEmoji(input) ? 'emoji': 'text';
	}

	const isEmoji = (htmlString) => {
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = htmlString;

		const pElement = tempDiv.querySelector('p');

		if (!pElement) {
			return false;
		}

		const spans = pElement.querySelectorAll('span.ql-emojiblot');

		if (spans.length === 1) {
			const span = spans[0];
			const emoji = span.querySelector('span.ap');

			if (emoji) {
				const textContent = pElement.textContent.trim();
				if (textContent === emoji.textContent.trim()) {
					return true;
				}
			}
		}

		return false;
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		const ans = answer.trim().toLowerCase()
		const res = values.answers.map((i) => i.trim().toLowerCase())

    if (res.includes(ans)) {
      setStatus('valid')
      const redirect = window.open(values.onSuccess.url, '_blank')
      redirect.focus()
    } else {
      setStatus('error')
    }
  }

  return (
    <StyledContainer $border={values.display.frame} $background={values.display.color}>
      {values.display.title && values.display.title !== '' && <h2>{values.display.title}</h2>}
      {values.display.subtitle && values.display.subtitle !== '' && <h3>{values.display.subtitle}</h3>}

      {values.display.textBefore && (
        <label htmlFor="dispatcherpass" style={{ marginBottom: '20px', textAlign: 'left', marginTop: 10 }}>
          <div dangerouslySetInnerHTML={{ __html: values.display.textBefore }} />
        </label>
      )}

      {typeButton === 'go' && (
        <>
        <StyledInputGoEmojiContainer>
            <Icon icon={lock} className="lock" />
            <StyledGoInput
              type="text"
              name="dispatcherpass"
              id="dispatcherpass"
              error={status === 'error'}
              valid={status === 'valid'}
              onChange={(e) => {
                setStatus('')
                setAnswer(e.target.value)
              }}
              autoComplete="off"
            />
            <StyledGoButton onClick={handleSubmit}>
              <span style={{ fontWeight: '700' }}>{values.display.textButton.replace('<p>', '').replace('</p>', '')}</span>
            </StyledGoButton>
          </StyledInputGoEmojiContainer>
          {status === 'error' && (
            <StyledErrorLabel htmlFor="dispatcherpass">{values.onError}</StyledErrorLabel>
          )}
        </>
      )}

      {typeButton === 'emoji' && (
        <>
          <StyledInputGoEmojiContainer>
            <Icon icon={lock} className="lock" />
            <StyledGoInput
              type="text"
              name="dispatcherpass"
              id="dispatcherpass"
              error={status === 'error'}
              valid={status === 'valid'}
              onChange={(e) => {
                setStatus('')
                setAnswer(e.target.value)
              }}
              autoComplete="off"
            />
            <StyledEmojiButton onClick={handleSubmit}>
           <span
             style={{ fontWeight: '700', fontSize: '20px' }}
             dangerouslySetInnerHTML={{ __html: values.display.textButton.replace('<p>', '').replace('</p>', '') }}
           />
            </StyledEmojiButton>
          </StyledInputGoEmojiContainer>
          {status === 'error' && (
            <StyledErrorLabel htmlFor="dispatcherpass">{values.onError}</StyledErrorLabel>
          )}
        </>
      )}

      {typeButton === 'text' && (
        <>
          <StyledInputContainer error={status === 'error'} valid={status === 'valid'}>
            <Icon icon={lock} className="lock" />

            <StyledInput
              type="text"
              name="dispatcherpass"
              id="dispatcherpass"
              error={status === 'error'}
              valid={status === 'valid'}
              onChange={(e) => {
                setStatus('')
                setAnswer(e.target.value)
              }}
              autoComplete="off"
            />

            {status === 'valid' && <Icon className="state" icon={check} />}
            {status === 'error' && <Icon className="state" icon={xCircle} />}

	          {status === 'error' && (
		          <StyledErrorLabel htmlFor="dispatcherpass">{values.onError}</StyledErrorLabel>
	          )}

	          <StyledButton onClick={handleSubmit}>
              <span
	              style={{ fontWeight: '700' }}
	              dangerouslySetInnerHTML={{ __html: values.display.textButton.replace('<p>', '').replace('</p>', '') }}
              />
	          </StyledButton>
          </StyledInputContainer>
        </>
      )}

      {values.display.textAfter && (
        <label htmlFor="dispatcherpass" style={{ marginBottom: '20px', marginTop: '10px', textAlign: 'left' }}>
          <div dangerouslySetInnerHTML={{ __html: values.display.textAfter }} />
        </label>
      )}
    </StyledContainer>
  )
}

Dispatcher.propTypes = {
  part: PropTypes.object,
}

export default Dispatcher

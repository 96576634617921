import PropTypes from 'prop-types'
import { BasicTextArea, BasicInfo, BasicButton, PartOptions } from 'components'
import { useIntl } from 'react-intl'
import { rteAppId } from 'utils/routes'

import { Icon } from 'react-icons-kit'
import { externalLink } from 'react-icons-kit/feather'

import ModuleForm from './ModuleForm'
import messages from './messages'
import { useEffect, useState } from 'react'

const GoogleFormsForm = ({ appId, onChange, part, partIndex, onFormChange }) => {
  const [iframeSrc, setIframeSrc] = useState('')
  const intl = useIntl()

  const extractIframeSrc = (html) => {
    const dom = document.createElement('html')
    dom.innerHTML = html
    const iframe = dom.getElementsByTagName('iframe')[0]

    if (iframe) {
      const src = iframe.getAttribute('src')
      setIframeSrc(src) // Mise à jour du state avec l'URL extraite
    }
  }

  useEffect(() => {
    if (part && part.text) {
      extractIframeSrc(part.text)
    }
  }, [part])

  const parseHtmlTag = (html) => {
    if (html.indexOf('iframe') < 0) {
      const e = { target: { id: 'src', value: html } }
      onChange(e, partIndex)
    } else {
      const dom = document.createElement('html')

      dom.innerHTML = html
      const iframe = dom.getElementsByTagName('iframe')[0]

      const data = {
        height: iframe.getAttribute('height'),
        src: iframe.getAttribute('src'),
      }

      Object.keys(data).forEach((key) => {
        const e = { target: { id: key, value: data[key] } }
        onChange(e, partIndex)
      })
    }
  }

  const handleOnChange = (e) => {
    onChange(e, partIndex)
    parseHtmlTag(e.target.value)
  }

  const handleValidation = (value) => {
    const isIframe = value.indexOf('<iframe src="https://docs.google.com/forms') >= 0
    const isGoogleForm = value.indexOf('https://docs.google.com/forms') >= 0
    return isIframe || isGoogleForm
  }

  return (
    <>
      <PartOptions
        moduleForm={<ModuleForm partIndex={partIndex} onChange={onChange} options={part.options} onFormChange={onFormChange} />}
        onChange={onChange}
        partIndex={partIndex}
        values={part.options}
        onFormChange={onFormChange}
      />

      <BasicTextArea
        name="text"
        partIndex={partIndex}
        label={intl.formatMessage(messages.googleForms)}
        helpMessage={intl.formatMessage(messages.googleFormsHelp)}
        value={part.text}
        onChange={(e) => {
          handleOnChange(e);
          onFormChange(true);
        }}
        error={intl.formatMessage(messages.googleFormsError)}
        required
        validation={{ async: (value) => handleValidation(value) }}
        size="small"
        link={iframeSrc}
      />

      <BasicInfo
        text={intl.formatMessage(messages.info)}
        children={
          <BasicButton
            label={intl.formatMessage(messages.tutorial)}
            icon={<Icon icon={externalLink} />}
            isLink
            href={rteAppId(appId, 'TUTORIAL')}
            target="_blank"
            linkstyle
            small
          />
        }
      />
    </>
  )
}

GoogleFormsForm.propTypes = {
  appId: PropTypes.string,
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default GoogleFormsForm

import PropTypes from 'prop-types'
import { BasicMultiSelect, BasicButton } from 'components'
import { useEffect, useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { MainContainerContext } from 'containers'

import { connectStore } from 'redux-box'
import { storeModule as CardModule } from 'store/card'

import { Icon } from 'react-icons-kit'
import { plus } from 'react-icons-kit/feather/plus'
import { folderOpenO } from 'react-icons-kit/fa/folderOpenO'
import { lock } from 'react-icons-kit/feather/lock'

import { StyledSelect } from './styles'
import messages from '../messages'

const Folders = ({ app, card, handleChange, onAddFolder, onFormChange, handleChangeFolders }) => {
  const [selectedFolders, setSelectedFolders] = useState([])
  const intl = useIntl()
  const context = useContext(MainContainerContext)

  useEffect(() => {
    setSelectedFolders([...getFolders()])
    handleChangeFolders([...getFolders()])
  }, [card.folders])

  const getFolders = () => {
    const values = card.folders.map((id) => app.getFolderById(id))
    return values.filter((item) => item) || []
  }

  const handleFolderChange = (values) => {
    setSelectedFolders(values)

    const value = values.map((v) => v.id)
    const event = { target: { id: 'folders', value } }
    handleChange(event)

    card.setFolders(value)
  }

  return (
    <>
      <StyledSelect>
        <BasicMultiSelect
          label={
            <>
              <Icon icon={folderOpenO} />
              {intl.formatMessage(messages.folder)}
            </>
          }
          name="folders"
          options={Object.values(app.folders)}
          optionsValue="name"
          onChange={(e) => {
            handleFolderChange(e);
            onFormChange(true);
          }}
          placeholder={intl.formatMessage(messages.select)}
          selectedValues={selectedFolders}
        />
      </StyledSelect>

      {!!app.access.Folders ? (
        <BasicButton
          onClick={onAddFolder}
          icon={<Icon icon={plus} />}
          label={intl.formatMessage(messages.addFolder)}
          small
          outlined
        />
      ) : (
        <BasicButton
          onClick={() => context.toggleUpgradeModal()}
          icon={<Icon icon={lock} />}
          label={intl.formatMessage(messages.addFolder)}
          small
          outlined
        />
      )}
    </>
  )
}

Folders.defaultProps = {
  onAddFolder: () => {},
}

Folders.propTypes = {
  app: PropTypes.object,
  card: PropTypes.object,
  handleChange: PropTypes.func,
  onAddFolder: PropTypes.func,
  onFormChange: PropTypes.func,
  handleChangeFolders: PropTypes.func,
}

export default connectStore({ card: CardModule })(Folders)

import styled from 'styled-components'

export const StyledLegend = styled.legend`
  text-transform: uppercase;
  color: #406cb3;
  font-weight: 600 !important;
  font-size: 14px !important;
`

export const Fieldset = styled.fieldset`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px 16px;
  align-items: center;
  margin-top: 0.5rem;
`

export const StyledBasicInput = styled.div`
  display: flex;
  align-items: center;
  gap: 10px 16px;
  ${(props) => props?.$gridColumn && `grid-column: span ${props.$gridColumn};`}
    
  div {
    flex: 1;

      label {
        margin-top: -10px;
        font-size: 12px;
        font-weight: 400;
      }
  }
`;

export const StyledBasicButton = styled.div`
  margin: 10px 0;
`

export const StyledSpanStatus = styled.span`
  ${(props) => props?.$color && `color: ${props.$color};`}
  ${(props) => props?.$gridColumn && `grid-column: ${props.$gridColumn};`}
`

export const StyledArrow = styled.div`
    ${(props) => props?.$color && `color: ${props.$color};`}
    ${(props) => props?.$gridColumn && `grid-column: ${props.$gridColumn};`}
    align-items: center;
    justify-content: flex-end;
`

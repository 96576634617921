import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import history from 'services/history'
import { rteCardsTag } from 'utils/routes'

import { connectStore } from 'redux-box'
import { storeModule as AppModule } from 'store/app'
import { storeModule as CardListModule } from 'store/cardList'
import { storeModule as CardModule } from 'store/card'
import { storeModule as DispatcherModule } from 'store/dispatchers'
import { storeModule as ModuleModules } from 'store/modules'
import { storeModule as LockModule } from 'store/locks'
import { storeModule as RandomStoryModule } from 'store/randomStory'
import { storeModule as SessionModule } from 'store/manageUsers'
import { storeModule as DataModule } from 'store/data'
import { storeModule as ManageAppsModule } from 'store/manageApps'
import { storeModule as ManageUsersModule } from 'store/manageUsers'

const AppManager = ({ app, appId, cardList, cardId, card, data, dispatchers, modules, locks, randomStory, session, manageApps, manageUsers }) => {
  // fetch user profile and appList
  // when hard-refresh and still connected
  useEffect(() => {
    const uid = Cookies.get('uid')
    if (uid && session.appList.length <= 0) session.getApps(uid)
  }, [session.appList])

  useEffect(() => {
    if (session.profile.superadmin) {
      const uid = Cookies.get('uid')
      if (manageUsers.users.list.length <= 0) manageUsers.getUserList(uid, appId)
      if (manageApps.apps.list.length <= 0) manageApps.getApps()
    }
    if(session.profile.role === 'admin' || session.profile.role === 'owner' || session.profile.role === 'superowner') {
      const uid = Cookies.get('uid');
      if (manageUsers.users.list.length <= 0) manageUsers.getUserList(uid, appId)
      if (manageApps.apps.list.length <= 0) manageApps.getApps()
    }
  }, [session.profile])

  useEffect(() => {
    let firstAppId = null;

    const appList = session.appList;
    if (!appList.find(app => app.appId === appId) && appList.length > 0) {
      firstAppId = appList[0].appId;
    }
    manageApps.getAppUserList(firstAppId || appId);
    if (firstAppId) {
      history.push(rteCardsTag(firstAppId, 'linked'));
    }
  }, [appId, session.appList])

  useEffect(() => {
    if (cardList.error) setTimeout(() => cardList.resetStatut(), 5000)
  }, [cardList.error])

  useEffect(() => {
    // get app info
    if (appId !== app.id) {
      app.getAppById(appId)
      fetchData()
    }
  }, [appId])

  useEffect(() => {
    if (cardId) {
      setTimeout(() => card.getCardById(cardId, appId), 10)
    }
  }, [cardId])

  const fetchData = () => {
    cardList.getCards(appId)
    modules.getAllModules(appId)
    locks.getLockList(appId)
    dispatchers.getDispatcherList(appId)
    randomStory.getRandomStoryList(appId)
    data.getThemes()
  }

  return null
}

AppManager.propTypes = {
  appId: PropTypes.string.isRequired,
  app: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  cardList: PropTypes.object.isRequired,
  cardId: PropTypes.string,
  data: PropTypes.object.isRequired,
  dispatchers: PropTypes.object,
  locks: PropTypes.object,
  randomStory: PropTypes.object,
  modules: PropTypes.object,
  manageApps: PropTypes.object,
  manageUsers: PropTypes.object,
  session: PropTypes.object,
}

export default connectStore({
  app: AppModule,
  cardList: CardListModule,
  card: CardModule,
  data: DataModule,
  dispatchers: DispatcherModule,
  manageApps: ManageAppsModule,
  manageUsers: ManageUsersModule,
  modules: ModuleModules,
  locks: LockModule,
  randomStory: RandomStoryModule,
  session: SessionModule,
})(AppManager)

import PropTypes from 'prop-types'
import { useContext } from "react";
import { useIntl } from 'react-intl'
import { BasicButton } from 'components'
import { MainContainerContext } from 'containers'
import history from 'services/history'
import EmptyCard from 'services/card.cls'
import { rteCardEditId, rteNewCard } from 'utils/routes'

import { connectStore } from 'redux-box'
import { storeModule as AppModule } from 'store/app'
import { storeModule as CardModule } from 'store/card'
import { storeModule as CardListModule } from 'store/cardList'

import { Icon } from 'react-icons-kit'
import { plusRound as plus } from 'react-icons-kit/ionicons/plusRound'
import { lock } from 'react-icons-kit/feather/lock'

import Collapse from './Collapse'
import Filters from './Filters'
import { StyledDiv } from './styles'
import messages from './messages'
import { checkActionsAccess } from "../../services/utils";
import { storeModule as ManageUsersModule, storeModule as SessionModule } from "../../store/manageUsers";

const CardFilters = ({ app, appId, session, card, cardList, currentFolder, noActive, onChange, changeCollapse, stateCollapse, onClick, tagId, manageUsers, changeHeaders, headers }) => {
  const context = useContext(MainContainerContext)

  const intl = useIntl()

  const handleAddCard = () => {
    const newCard = new EmptyCard(appId)
    if (currentFolder) {
      newCard.folders = [...newCard.folders, currentFolder.id]
      if(!manageUsers.profile.superadmin) {
        manageUsers.updateUserRights(app.id, manageUsers.profile, {
          type: 'cards',
          name: newCard.title.trim(),
          card: newCard,
        });
      }
      card.saveCard(newCard, app)
      history.push(rteCardEditId(appId, newCard.cardId))
    } else {
      cardList.addCard(newCard)
      app.addCard(newCard.cardId)
      history.push(rteNewCard(appId, newCard.cardId))
    }
  }

  return session.profile.email !== '' && (
    <StyledDiv>
      <StyledDiv>
        <div style={{ marginRight: '20px' }}>
          {currentFolder
               ? checkActionsAccess(appId, session.profile, 'pages', 'add') && (
              <>
                {!!app.access.Folders ? (
                  <BasicButton
                    color="green"
                    onClick={handleAddCard}
                    icon={<Icon icon={plus} />}
                    label={intl.formatMessage(messages.addUnlinkedCard)}
                  />
                ) : checkActionsAccess(appId, session.profile, 'pages', 'add') && (
                  <BasicButton
                    color="green"
                    onClick={() => context.toggleUpgradeModal()}
                    icon={<Icon icon={lock} />}
                    label={intl.formatMessage(messages.addUnlinkedCard)}
                  />
                )}
              </>
            ) : checkActionsAccess(appId, session.profile, 'pages', 'add') && (
              <BasicButton
                color="green"
                onClick={handleAddCard}
                icon={<Icon icon={plus} />}
                label={intl.formatMessage(messages.addUnlinkedCard)}
              />
            )
          }
        </div>

        <Filters
          currentFolder={currentFolder}
          tagId={tagId}
          appId={appId}
          noActive={noActive}
          onClick={onClick}
        />
      </StyledDiv>

      <Collapse onChange={onChange} changeHeaders={changeHeaders} headers={headers} stateCollapse={stateCollapse} changeCollapse={changeCollapse} />
    </StyledDiv>
  )
}

CardFilters.propTypes = {
  app: PropTypes.object,
  session: PropTypes.object,
  appId: PropTypes.string,
  card: PropTypes.object,
  cardList: PropTypes.object,
  currentFolder: PropTypes.object,
  noActive: PropTypes.bool,
  onChange: PropTypes.func,
  changeCollapse: PropTypes.func,
  onClick: PropTypes.func,
  tagId: PropTypes.string,
  stateCollapse: PropTypes.string,
	changeHeaders: PropTypes.func,
	headers: PropTypes.object,
}

export default connectStore({ app: AppModule, card: CardModule, cardList: CardListModule, session: SessionModule, manageUsers: ManageUsersModule })(CardFilters)

import { defineMessages } from 'react-intl'

const scope = 'app.components.BasicInputArrayRedirectService'

export default defineMessages({
  addLabel: { id: `${scope}.addLabel` },
  enterYourDomain: { id: `${scope}.enterYourDomain` },
  domainErrorValid: { id: `${scope}.domainErrorValid` },
  enterNameYouBought: { id: `${scope}.enterNameYouBought` },
  pending: { id: `${scope}.pending` },
  connected: { id: `${scope}.connected` },
  failed: { id: `${scope}.failed` },
  addThisDomain: { id: `${scope}.addThisDomain` },
  pleaseFillAllInput: { id: `${scope}.pleaseFillAllInput` },
  enterUrlToRedirect: { id: `${scope}.enterUrlToRedirect` },
  addThisRedirection: { id: `${scope}.addThisRedirection` },
  confirmDeleteDomain: { id: `${scope}.confirmDeleteDomain` },
  warningBeforeDeleteDomain: { id: `${scope}.warningBeforeDeleteDomain` },
  cancel: { id: `${scope}.cancel` },
  deleteThisDomain: { id: `${scope}.deleteThisDomain` },
  customizeYourUrl: { id: `${scope}.customizeYourUrl` },
})

import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { UncontrolledDropdown } from 'reactstrap'
import { useIntl } from 'react-intl'
import { Icon } from 'react-icons-kit'
import { moreVertical } from 'react-icons-kit/feather/moreVertical'

import {
	StyledCheckboxItem,
	StyledHeadersDropdown,
	StyledHeadersDropdownToggle,
	StyledItemsContainer,
	StyledLabelItem, StyledResetButton,
} from './styles'
import messages from './messages'

const Headers = ({ headers, changeHeaders }) => {
  const intl = useIntl()
	const items = [
		{ id: 'projects', label: intl.formatMessage(messages.headersProject) },
		{ id: 'theme', label: intl.formatMessage(messages.headersTheme) },
		{ id: 'gaTitle', label: intl.formatMessage(messages.headersGaTitle) },
		{ id: 'language', label: intl.formatMessage(messages.headersLanguage) },
		/*{ id: 'logo', label: intl.formatMessage(messages.headersLogo) },*/
	];

  const handleOnChange = (e) => {
	  const { id, checked } = e.target;
	  changeHeaders((prevHeaders) => ({
		  ...prevHeaders,
		  [id]: checked,
	  }));
  }

	const handleReset = () => {
		const resetHeaders = Object.keys(headers).reduce((acc, key) => {
			acc[key] = true;
			return acc;
		}, {});
		changeHeaders(resetHeaders);
	};

  return <>
	  <div>
		  <UncontrolledDropdown style={{listStyle: 'none'}}>
			  <StyledHeadersDropdownToggle>
				  <Icon icon={moreVertical}/>
			  </StyledHeadersDropdownToggle>

			  <StyledHeadersDropdown>
				  {items.map(({ id, label }) => (
					  <StyledItemsContainer key={id}>
						  <StyledCheckboxItem
							  type="checkbox"
							  id={id}
							  checked={headers[id]}
							  onChange={handleOnChange}
						  />
						  <StyledLabelItem htmlFor={id}>{label}</StyledLabelItem>
					  </StyledItemsContainer>
				  ))}
				  <StyledResetButton onClick={handleReset}>
					  {intl.formatMessage(messages.headersReset)}
					</StyledResetButton>
			  </StyledHeadersDropdown>
		  </UncontrolledDropdown>
	  </div>
	</>
}

Headers.propTypes = {
	headers: PropTypes.object,
	changeHeaders: PropTypes.func,
}

export default withRouter(Headers)

import styled from 'styled-components'

export const StyledContainer = styled.div`
  position: relative;
  padding: 1.25rem 1.25rem 0 18px;
	margin-bottom: 20px;

  @media (max-width: 568px) {
    padding-right: 3rem;
  }
`

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;

  h2 {
    font-family: 'Nunito', sans-serif;
    font-size: 26px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.7px;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex: 1;
    margin-left: 10px;
    margin-bottom: 0;
    margin-right: 34px;
  }
`

export const StyledLegend = styled.legend`
  font-style: italic;
  font-size: 12px;
  color: #6c757d;
  padding: 5px 5px 0 5px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
`

export const StyledButtons = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  button:first-of-type {
    margin-bottom: 8px;
  }

  button,
  a {
    border: 1px solid #12263f;
    color: #12263f;
    width: 27px;
    height: 27px;

    &:hover {
      color: #12263f !important;
      border: 1px solid #12263f !important;
      background: transparent !important;
    }
  }
`

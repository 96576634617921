import PropTypes from 'prop-types'
import { BasicCheckbox, BasicInput } from 'components'
import { useIntl } from 'react-intl'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as AppModule } from 'store/app'

import messages from './messages'

const StyleForm = ({ app, part, partIndex, onChange, onCheckedChange, onFormChange, currentStyle }) => {
  const intl = useIntl()

  return (
    <>
      <BasicCheckbox
        partIndex={partIndex}
        label={intl.formatMessage(messages.alternate)}
        helpMessage={intl.formatMessage(messages.alternateHelp)}
        name="style.alternate"
        value={currentStyle.alternate || false}
        onChange={(e) => {
          onCheckedChange(e);
          onFormChange(true);
        }}
      />

	    <BasicCheckbox
		    partIndex={partIndex}
		    label={intl.formatMessage(messages.justify)}
		    name="style.justify"
		    value={currentStyle.justify || false}
		    onChange={(e) => {
			    onCheckedChange(e);
			    onFormChange(true);
		    }}
	    />
      <BasicInput
        partIndex={partIndex}
        type="select"
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        label={intl.formatMessage(messages.BorderRadiusLabel)}
        helpMessage={intl.formatMessage(messages.BorderRadiusHelp)}
        name="style.borderRadius"
        value={currentStyle.borderRadius || '10'}
      >
        <option value="0">{intl.formatMessage(messages.BorderRadius0)}</option>
        <option value="10">{intl.formatMessage(messages.BorderRadius10)}</option>
        <option value="25">{intl.formatMessage(messages.BorderRadius25)}</option>
      </BasicInput>

      <BasicInput
        partIndex={partIndex}
        type="select"
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        label={intl.formatMessage(messages.BorderWidthLabel)}
        helpMessage={intl.formatMessage(messages.BorderWidthHelp)}
        name="style.borderWidth"
        value={currentStyle.borderWidth || '3'}
      >
        <option value="1">{intl.formatMessage(messages.BorderWidth1)}</option>
        <option value="2">{intl.formatMessage(messages.BorderWidth2)}</option>
        <option value="3">{intl.formatMessage(messages.BorderWidth3)}</option>
      </BasicInput>
    </>
  )
}

StyleForm.defaultProps = {
  currentStyle: {},
  onChange: () => {},
}

StyleForm.propTypes = {
  app: PropTypes.object,
  isExternalLink: PropTypes.bool,
  onChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
  currentStyle: PropTypes.object,
}

export default connectStore({ app: AppModule })(StyleForm)

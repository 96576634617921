const actions = {
  getDispatcherList: (appId) => ({ type: 'DISPATCHER_GET_LIST', appId }),
  deleteDispatcher: (id, appId) => ({ type: 'DISPATCHER_DELETE', id, appId }),
  saveDispatcher: (appId, cardId, dispatcher, onSave) => ({
    type: 'DISPATCHER_SAVE',
    appId,
    cardId,
    dispatcher,
    onSave,
  }),
  resetStatus: () => ({ type: 'DISPATCHER_STATUS_RESET' }),
}

export default actions

import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.DispatcherForm'

export default defineMessages({
  create: { id: `${scope}.create` },
  deletedDispatcher: { id: `${scope}.deletedDispatcher` },
  edit: { id: `${scope}.edit` },
  error: { id: `${scope}.error` },
  infoDispatcher: { id: `${scope}.infoDispatcher` },
  dispatchers: { id: `${scope}.dispatchers` },
  select: { id: `${scope}.select` },
  tutorial: { id: `${scope}.tutorial` },
})

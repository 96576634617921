import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {
  BasicInput,
  BasicInfo,
  BasicLabel,
  BasicMultiInputArray,
} from 'components'

import { StyledAnswersContainer, StyledAnswersFullWidth, StyledAnswersSemiWidth, StyledLegend } from './styles'
import messages from '../messages'

const Answers = ({ dispatcher }) => {
  const intl = useIntl()

  return (
    <>
      <StyledLegend>{intl.formatMessage(messages.answers)}</StyledLegend>

      <BasicInfo text={intl.formatMessage(messages.answersInfo)} />

      <StyledAnswersContainer>
        <StyledAnswersSemiWidth>
          <BasicLabel label={intl.formatMessage(messages.acceptedAnswers)} required />
        </StyledAnswersSemiWidth>
        <StyledAnswersSemiWidth>
          <BasicLabel label={intl.formatMessage(messages.redirect)} required />
        </StyledAnswersSemiWidth>
        <StyledAnswersFullWidth>
          <BasicLabel helpMessage={intl.formatMessage(messages.answersHelp)} />
        </StyledAnswersFullWidth>

        <StyledAnswersFullWidth>
          <BasicMultiInputArray
            name={'answers'}
            label=""
            list={dispatcher ? dispatcher.answers : null}
            errorMessage={intl.formatMessage(messages.answerErrors)}
            addLabel={intl.formatMessage(messages.addAnswers)}
            required
          />
        </StyledAnswersFullWidth>

        <StyledAnswersFullWidth>
          <BasicInput
            name="onError"
            label={intl.formatMessage(messages.refused)}
            error={intl.formatMessage(messages.refusedError)}
            helpMessage={intl.formatMessage(messages.refusedHelp)}
            placeholder={intl.formatMessage(messages.refusedPlaceholder)}
            required
          />
        </StyledAnswersFullWidth>
      </StyledAnswersContainer>
    </>
  )
}

Answers.propTypes = {
  dispatcher: PropTypes.object,
}

export default Answers

import PropTypes from "prop-types";
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { RawEditor, BasicInput, BasicWarning, BasicTooltip, Pastille, BasicCheckbox } from "components";
import uid from 'uid'

import { Icon } from 'react-icons-kit'
import { iosGearOutline } from 'react-icons-kit/ionicons/iosGearOutline'
import { iosFolderOutline } from 'react-icons-kit/ionicons/iosFolderOutline'

import PropertiesPanel from './PropertiesPanel'
import { StyledHeaderContainer, StyledIconButton, StyledInnerContainer, StyledIconContainer } from './styles'
import messages from './messages'
import { BasicTextArea } from 'components'
import { checkActionsAccess } from "../../services/utils";
import { connectStore } from "redux-box";
import { storeModule as SessionModule } from "../../store/manageUsers";

const CardEditHeader = ({
  app,
  handleAllPartsOverride,
  handleChange,
  onCheckedChange,
  parts,
  place,
  onAddFolder,
  title,
  tagGaIsChecked,
  tagGaLabel,
  tokenId,
  onFormChange,
  handleChangeFolders,
  session
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [option, setOption] = useState()
  const [activeOpt, setActiveOpt] = useState()
  const [tagGaChecked, setTagGaChecked] = useState(tagGaIsChecked)
  const [showTagGA, setShowTagGA] = useState(!tagGaIsChecked)

  const themeId = uid()
  const folderId = uid()

  const intl = useIntl()

  const toggleOpenCollapse = (opt) => {
    if (option === opt) {
      toggleCloseCollapse()
    } else {
      setIsOpen(true)
      setOption(opt)
      setActiveOpt(opt)
    }
  }

  const toggleCloseCollapse = () => {
    setIsOpen(false)
    setOption('')
  }

  const handleValidationText = (value) => {
    if(value !== '') {
      return value.trim() !== '';
    }
    return true;
  }

  const handleCheckedChange = (e) => {
    const v = e.target.checked;
    setTagGaChecked(v);
    setShowTagGA(!v);
    handleChange(e);
  }

  return (
    <>
      <PropertiesPanel
        app={app}
        isOpen={isOpen}
        toggleClose={toggleCloseCollapse}
        handleChange={handleChange}
        onCheckedChange={onCheckedChange}
        option={activeOpt}
        onAddFolder={onAddFolder}
        onFormChange={onFormChange}
        handleChangeFolders={handleChangeFolders}
      />

      <StyledHeaderContainer className={isOpen && 'open'}>
        <StyledInnerContainer>
          <Pastille number={tokenId} />

          <div style={{ flexGrow: '1' }}>
            <BasicTextArea
              label={intl.formatMessage(messages.title)}
              placeholder={intl.formatMessage(messages.title)}
              name="title"
              value={title}
              onChange={(e) => {
                handleChange(e);
                onFormChange(true);
              }}
              error={'Vous devez saisir du texte ou laisser vide'/*intl.formatMessage(messages.textError)*/}
              size="xsmall"
              validation={{
                async: (value) => handleValidationText(value),
              }}
            />

            <BasicCheckbox
              label={intl.formatMessage(messages.googleAnalyticsChecked)}
              helpMessage={intl.formatMessage(messages.googleAnalyticsHelp)}
              name="tagGaChecked"
              id="tagGaChecked"
              value={tagGaChecked}
              onChange={(e) => {
                handleCheckedChange(e);
                onFormChange(true);
              }}
            />

            {showTagGA && <BasicInput
              label={intl.formatMessage(messages.googleAnalyticsLabel)}
              type="text"
              name="tagGaLabel"
              id="tagGaLabel"
              placeholder={intl.formatMessage(messages.googleAnalyticsLabel)}
              value={tagGaLabel}
              onChange={(e) => {
                handleChange(e);
                onFormChange(true);
              }}
            />}

            <BasicInput
              label={intl.formatMessage(messages.place)}
              type="text"
              name="place"
              id="place"
              placeholder={intl.formatMessage(messages.place)}
              value={place}
              onChange={(e) => {
                handleChange(e);
                onFormChange(true);
              }}
            />
          </div>
        </StyledInnerContainer>

        <StyledIconContainer>
          <StyledIconButton
            onClick={() => toggleOpenCollapse('theme')}
            className={option === 'theme' && 'active'}
            data-for={themeId}
            data-tip=""
          >
            <Icon icon={iosGearOutline} />
          </StyledIconButton>

          <BasicTooltip id={themeId} content={intl.formatMessage(messages.settings)} />

          {app.id !== '' && checkActionsAccess(app.id, session.profile, 'pages', 'folders') &&
          <StyledIconButton
            onClick={() => toggleOpenCollapse('folders')}
            className={option === 'folders' && 'active'}
            isfolder="true"
            data-for={folderId}
            data-tip=""
          >
            <Icon icon={iosFolderOutline} />
          </StyledIconButton>}

          <BasicTooltip id={folderId} content={intl.formatMessage(messages.folder)} />
        </StyledIconContainer>
      </StyledHeaderContainer>

      {place.indexOf('$') === 0 && (
        <StyledInnerContainer style={{ margin: '0 0 20px 0' }}>
          <BasicWarning style={{ padding: 0 }} text={intl.formatMessage(messages.themeInLocationWarning)} />
        </StyledInnerContainer>
      )}

      <RawEditor parts={parts} onChange={handleAllPartsOverride} />
    </>
  )
}

CardEditHeader.propTypes = {
  app: PropTypes.object,
  handleAllPartsOverride: PropTypes.func,
  handleChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
  onAddFolder: PropTypes.func,
  parts: PropTypes.array,
  place: PropTypes.any,
  title: PropTypes.any,
  tokenId: PropTypes.number,
  onFormChange: PropTypes.func,
  handleChangeFolders: PropTypes.func,
  session: PropTypes.object,
}

export default connectStore({ session: SessionModule })(CardEditHeader)

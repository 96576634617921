// css
import 'antd/dist/antd.css'
import 'assets/fontface.css'
import 'assets/bootstrap.css'

import { HOST_TYPE, ROLLBAR_ACCESS_TOKEN } from './utils/consts'

import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'
import { ConnectedRouter } from 'react-router-redux'
import history from 'services/history'
import { ConfigProvider } from 'antd'
import App from 'containers/App'
import configureStore from 'store'
import LanguageProvider from 'containers/LanguageProvider'

// store
const initialState = {}
const store = configureStore(initialState, history)

// rollbar
const rollbarConfig = {
  accessToken: ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: HOST_TYPE,
  telemetry: {
    autoInstrument: {
      log: false,
    }
  }
}



ReactDOM.render(
  <>
    <Provider store={store}>
      <RollbarProvider config={rollbarConfig}>
        <ErrorBoundary>
          <LanguageProvider>
            <ConfigProvider>
              <ConnectedRouter history={history}>
                <App />
              </ConnectedRouter>
            </ConfigProvider>
          </LanguageProvider>
        </ErrorBoundary>
      </RollbarProvider>
    </Provider>
  </>,
  document.getElementById('root'),
)

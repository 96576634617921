const actions = {
  getAppById: (appId) => ({ type: 'APP_FETCH_BY_ID', appId }),
  saveSettings: (data) => ({ type: 'APP_SAVE_SETTINGS', data }),
  saveFolder: (appId, folder, cardId) => ({ type: 'APP_SAVE_FOLDER', folder, appId, cardId }),
  sortFolders: (appId, value) => ({ type: 'APP_SORT_FOLDERS', appId, value }),
  switchApp: (appId) => ({ type: 'APP_SWITCH', appId }),
  resetStatus: () => ({ type: 'APP_RESET_STATUS' }),
  saveRedirectService: (app, data) => ({ type: 'APP_SAVE_REDIRECT_SERVICE', app, data }),

  addCard: (cardId) => ({ type: 'APP_ADD_CARD', cardId }),
  deleteFolder: (data) => ({ type: 'FOLDER_DELETE_REQUEST', ...data }),
}
export default actions

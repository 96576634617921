import PropTypes from 'prop-types'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { BasicModal, BasicButton } from 'components'

import messages from './messages'

const ButtonWithConfirm = ({
  onConfirm,
  color,
  disabled,
  label,
  linkstyle,
  medium,
  modalTitle,
  modalText,
  modalSize,
  icon,
  outlined,
  round,
  small,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const intl = useIntl()

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const handleOnConfirm = () => {
    toggleModal()
    onConfirm()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleOnConfirm()
    }
  }

  return (
    <>
      <BasicButton
        outlined={outlined}
        color={color}
        icon={icon}
        disabled={disabled}
        onClick={toggleModal}
        linkstyle={linkstyle}
        medium={medium}
        small={small}
        round={round}
        label={label}
      />

      {isModalOpen && (
        <BasicModal
          title={modalTitle}
          content={modalText}
          actions={
            <>
              <BasicButton onClick={toggleModal} label={intl.formatMessage(messages.cancel)} outlined />
              <BasicButton
                onClick={handleOnConfirm}
                label={intl.formatMessage(messages.confirm)}
                color="green"
              />
            </>
          }
          isOpen={isModalOpen}
          toggle={toggleModal}
          size={modalSize}
          onKeyDown={handleKeyDown}
        />
      )}
    </>
  )
}

ButtonWithConfirm.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.any,
  linkstyle: PropTypes.bool,
  onConfirm: PropTypes.func,
  medium: PropTypes.bool,
  modalSize: PropTypes.string,
  modalText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  modalTitle: PropTypes.string,
  small: PropTypes.bool,
  round: PropTypes.bool,
  outlined: PropTypes.bool,
}

export default ButtonWithConfirm

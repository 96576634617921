import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicInput, BasicInfo, BasicLabel } from 'components'
import { HEX_COLORS_DISPATCHER } from 'utils/consts'

import { StyledLegend, StyledColorPicker } from './styles'
import messages from '../messages'
import Circle from '@uiw/react-color-circle'
import { useState } from 'react'
import BasicCheckbox from '../../../components/BasicCheckbox'
import QuillEditor from './QuillEditor';

const GeneralInfo = ({ getDispatcherByName, id, color, data }) => {
  const [hex, setHex] = useState(color)
  const intl = useIntl()

  const handleValidation = (value) => {
    const dispatcher = getDispatcherByName(value) || []
    const differentId = dispatcher.filter((l) => l.id !== id)
    return differentId.length <= 0
  }

  return (
    <>
      <BasicInput
        name="name"
        label={intl.formatMessage(messages.name)}
        error={intl.formatMessage(messages.nameError)}
        helpMessage={intl.formatMessage(messages.nameHelp)}
        placeholder={intl.formatMessage(messages.namePlaceholder)}
        required
        validation={{
          async: (value) => handleValidation(value),
        }}
      />

      <hr />

      <StyledLegend>{intl.formatMessage(messages.content)}</StyledLegend>

      <BasicInfo text={intl.formatMessage(messages.contentHelp)} />

      <BasicInput
        name="display.title"
        label={intl.formatMessage(messages.title)}
        helpMessage={intl.formatMessage(messages.titleHelp)}
        placeholder={intl.formatMessage(messages.titlePlaceholder)}
      />

      <BasicInput
        name="display.subtitle"
        label={intl.formatMessage(messages.subtitle)}
        helpMessage={intl.formatMessage(messages.subtitleHelp)}
        placeholder={intl.formatMessage(messages.subtitlePlaceholder)}
      />

      <QuillEditor
        value={data ? data.display.textBefore : ''}
        label={intl.formatMessage(messages.textBefore)}
        helpMessage={intl.formatMessage(messages.textBeforeHelp)}
        placeholder={intl.formatMessage(messages.textBeforePlaceholder)}
        name="display.textBefore"
      />

      <QuillEditor
        value={data ? data.display.textButton : ''}
        label={intl.formatMessage(messages.typeButton)}
        helpMessage={intl.formatMessage(messages.typeButtonHelp)}
        placeholder={intl.formatMessage(messages.typeButtonPlaceholder)}
        name="display.textButton"
      />

      <QuillEditor
        value={data ? data.display.textAfter : ''}
        label={intl.formatMessage(messages.textAfter)}
        helpMessage={intl.formatMessage(messages.textAfterHelp)}
        placeholder={intl.formatMessage(messages.textAfterPlaceholder)}
        name="display.textAfter"
      />

      <StyledLegend>{intl.formatMessage(messages.appearance)}</StyledLegend>

      <StyledColorPicker>
        <BasicInput
          name="display.color"
          value={hex}
          type="hidden"
        />
        <BasicLabel
          label={intl.formatMessage(messages.color)}
          helpMessage={intl.formatMessage(messages.colorHelp)}
        />
        <Circle color={hex} onChange={(color) => setHex(color.hex)} colors={HEX_COLORS_DISPATCHER} />
      </StyledColorPicker>

      <BasicCheckbox
        label={intl.formatMessage(messages.frame)}
        helpMessage={intl.formatMessage(messages.frameHelp)}
        name="display.frame"
      />
    </>
  )
}

GeneralInfo.propTypes = {
  id: PropTypes.string,
  getDispatcherByName: PropTypes.func,
  color: PropTypes.string,
  data: PropTypes.object,
}

export default GeneralInfo

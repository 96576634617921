import styled from 'styled-components'
import { styles } from '../../assets/styles-variables'
import { DropdownMenu, DropdownToggle } from 'reactstrap'

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media screen and (max-width: 960px) {
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const StyledSelect = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  font-family: Nunito;
  font-weight: 700;
  font-size: 13px;

  select {
    box-sizing: border-box;
    font-family: Nunito;
    font-weight: 700;
    font-size: 13px;
    border: 1px solid #12263f;
    border-radius: 6px;
    margin-left: 10px;
    background-color: transparent;
    padding: 2px 5px 0 5px;
    height: fit-content;
    min-width: 115px;
    width: 115px;

    &:focus,
    &:active {
      background-color: transparent;
    }
  }
`

export const StyledSmallLabel = styled.span`
  font-family: Nunito;
  font-weight: 700;
  font-size: 13px;
  line-heigth: 22px;

  @media screen and (max-width: 960px) {
    display: none;
  }
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 960px) {
    margin-top: 24px;
  }
`

export const StyledButtons = styled.div`
  a {
    padding: 0.1rem 0.75rem;
    background: transparent;
    font-size: 0.8rem;
    font-weight: 600;
    color: #03162c;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    white-space: nowrap;
    margin-top: 10px;

    @media screen and (max-width: 960px) {
      margin-top: 0;
    }

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    &.active,
    &.active:focus,
    &.active:active {
      background-color: ${styles.color.yellow} !important;
      border: 1px solid ${styles.color.yellow} !important;
      color: ${styles.color.main} !important;
      cursor: default;
    }

    &:hover,
    &:focus,
    &:active {
      color: ${styles.color.main};
      background-color: ${styles.color.yellow};
      border: 1px solid ${styles.color.yellow};
    }
  }
`

export const StyledHeadersDropdown = styled(DropdownMenu)`
  font-family: Nunito,serif;
  font-size: 16px;
  margin-top: 10px;
  overflow-y: auto;
	padding: 5px;
	font-weight: lighter;
  left: -220%;
`

export const StyledItemsContainer = styled.div`
	display: flex;
  height: 30px;
	cursor: pointer;
`

export const StyledLabelItem = styled.label`
	margin-left: 5px;
	line-height: 30px;
  cursor: pointer;
`

export const StyledCheckboxItem = styled.input`
    cursor: pointer;
`

export const StyledHeadersDropdownToggle = styled(DropdownToggle)`
  padding: 0;
  margin: 0 0 0 5px;
  background-color: transparent;
	border-radius: 6px;
  color: black;
  border-color: black;
	height: 24px;
	width: 20px;
`

export const StyledResetButton = styled.div`
  font-weight: 600;
  color: white;
  background-color: #416db2;
  border: 2px solid #416db2;
  border-radius: 8px;
  text-align: center;
  width: 55%;
  margin: auto;
	cursor: pointer;
`
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { info } from 'react-icons-kit/feather/info'
import { Icon } from 'react-icons-kit'
import { FormText } from 'reactstrap'

const StyledInfoContainer = styled.div`
  display: ${({ $forLocks }) => ($forLocks ? '' : 'flex')};
  flex-direction: ${({ wrap }) => (wrap === 'true' ? 'column' : 'row')};
  ${({ wrap }) => wrap !== 'true' && 'justify-content: space-between'};
  align-items: flex-start;
  padding: 5px 0;

  div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 5px;
  }

  @media (max-width: 460px) {
    flex-direction: column;

    a {
      margin-left: 13px;
    }
  }

  small {
    margin: 0;
  }
`

const StyledIcon = styled(Icon)`
  margin-right: 5px;
  color: #6c757d;
  display: flex !important;

  svg {
    width: 20px;
    height: 20px;
  }
`

const BasicInfo = ({ children, text, wrap, forLocks = false, ...props }) => (
  <StyledInfoContainer wrap={String(wrap)} $forLocks={forLocks}>
    <div>
      <StyledIcon icon={info} />
      <FormText color="muted">{text}</FormText>
    </div>
    {children}
  </StyledInfoContainer>
)

BasicInfo.propTypes = {
  text: PropTypes.any,
  children: PropTypes.node,
  wrap: PropTypes.bool,
  forLocks: PropTypes.bool
}

export default BasicInfo

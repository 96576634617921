import { BasicButton } from "components";
import messages from "./message";
import { FormattedMessage, useIntl } from "react-intl";

import { connectStore } from "redux-box";
import { storeModule as ManageUsersModule } from "store/manageUsers";

import PropTypes from "prop-types";
import { Icon } from "react-icons-kit";
import { trash } from "react-icons-kit/feather/trash";
import { chevronRight } from "react-icons-kit/feather/chevronRight";
import { chevronDown } from "react-icons-kit/feather/chevronDown";
import { plusCircle } from "react-icons-kit/feather/plusCircle";
import { lock } from "react-icons-kit/feather/lock";
import {
  StyledCollapse,
  StyledCollapseDiv,
  StyledCollapseLegend, StyledDateErrorMessage,
  StyledFormButtonAction,
  StyledFormContainer,
  StyledFormDatePicker,
  StyledFormSelect,
  StyledHelpMessage,
  StyledNoFormContainer,
  StyledUserIcon,
  StyledUserInfo
} from "./styles";

import { useEffect, useState } from "react";
import { DatePicker } from "antd";
import moment from "moment/moment";
import localeFr from "antd/lib/date-picker/locale/fr_FR";
import localeEn from "antd/lib/date-picker/locale/en_US";
import { AvForm } from "availity-reactstrap-validation";
import { ADMIN_SHORT_URL, DATEPICKER_OPTIONS } from "../../utils/consts";
import { checkAccessDates } from "../../utils/share";

const UsersShare = ({ user, cardId, tokenId, appId, index, manageUsers}) => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const format = DATEPICKER_OPTIONS.dateFormat[intl.locale];
  const app = user.apps.find(app => app.appId === appId);
  const roleGlobal = app && app.roles && app.roles.length > 0 ? app.roles[0] : { access: { linked: [], unlinked: [] } };
  const [roleCard, setRoleCard] = useState(null);
  const currentUser = manageUsers.profile;
  const dns = `${ADMIN_SHORT_URL}/${appId}/manageusers`;
  const [dateError, setDateError] = useState('');

  useEffect(() => {
    if (tokenId === 0 && roleGlobal.access.unlinked) {
      const found = roleGlobal.access.unlinked.find(card => card.cardId === cardId);
      if (found) {
        setRoleCard(found);
      }
    } else {
      if(roleGlobal.access.linked) {
        const found = roleGlobal.access.linked.find(card => card.cardId === cardId);
        if (found) {
          setRoleCard(found);
        }
      }
    }
  }, [roleGlobal, tokenId, cardId]);

  const getButtonText = (hasAccess, mode) => {
    if(roleGlobal.role !== 'superowner' && roleGlobal.role !== 'owner' && roleGlobal.role !== 'admin' && hasAccess) {
      if (mode === 'remove') {
        return intl.formatMessage(messages.removeAccess);
      } else {
        return intl.formatMessage(messages.editAccess);
      }
    } else {
      return intl.formatMessage(messages.addAccess);
    }
  };

  const getButtonIcon = () => {
    if((currentUser.role === 'superowner' || currentUser.role === 'owner' || currentUser.role === 'admin') && roleGlobal.role !== 'owner' && roleGlobal.role !== 'admin') {
      if(roleGlobal.typeAccess === 'none_exept' && roleGlobal.hasAccess) {
         return trash;
      }
      if (roleGlobal.typeAccess === 'none_exept' && !roleGlobal.hasAccess) {
        return plusCircle;
      }
    }

    return lock;
  };

  const getRoleText = (role) => {
    switch (role) {
      case 'superowner':
        return intl.formatMessage(messages.role_superowner);
      case 'owner':
        return intl.formatMessage(messages.role_owner);
      case 'admin':
        return intl.formatMessage(messages.role_admin);
      case 'editor':
        return intl.formatMessage(messages.role_editor);
      case 'redactor':
        return intl.formatMessage(messages.role_redactor);
      default:
        return intl.formatMessage(messages.role_viewer);
    }
  };

  const getLabelAccess = (role, typeAccess) => {
    if(role === 'superowner' || role === 'owner' || role === 'admin') {
      return intl.formatMessage(messages.allAccess);
    }

    if(typeAccess === 'card') {
      return intl.formatMessage(messages.customAccess);
    } else {
      return intl.formatMessage(messages.hasAccess);
    }
  };

  const getPeriod = (end) => {
    if(end && end !== '') {
      return <FormattedMessage
        {...messages.periodToEnd}
        values={{ date: end }}
      />;
    }
    return intl.formatMessage(messages.noPeriod);
  }

  const handleDateChange = (date, dateString, type) => {
    const startDate = type === 'startDate' ? dateString : roleCard && roleCard.startDate ? roleCard.startDate : '';
    const endDate = type === 'endDate' ? dateString : roleCard && roleCard.endDate ? roleCard.endDate : '';

    if(checkDate(startDate, endDate)) {
      setDateError('La date de début doit être antérieure à la date de fin');
      return;
    } else {
      setDateError('')
    }

    const updatedRole = {
      ...roleCard,
      cardId: cardId,
      startDate: startDate,
      endDate: endDate,
    };

    setRoleCard(updatedRole);
  };

  const handleRemoveCard = () => {
    if(tokenId === 0) {
      roleGlobal.access.unlinked = roleGlobal.access.unlinked.filter(card => card.cardId !== cardId);
    } else {
      roleGlobal.access.linked = roleGlobal.access.linked.filter(card => card.cardId !== cardId);
    }
    delete user.hasAccess;

    manageUsers.saveUser(user, true);
  }

  const handleOnSubmit = (type) => {
    if(dateError !== '') {
      return;
    }

    const finalRoleCard = {
      ...roleCard,
      cardId: cardId,
    };

    if(type === 'linked') {
      let foundInLinked = false;
      roleGlobal.access.linked = roleGlobal.access.linked.map(linkedCard => {
        if (linkedCard.cardId === finalRoleCard.cardId) {
          foundInLinked = true;
          return {...finalRoleCard};
        }
        return linkedCard;
      });
      if (!foundInLinked) {
        roleGlobal.access.linked.push(finalRoleCard);
      }
    }

    if(type === 'unlinked') {
      let foundInUnlinked = false;
      roleGlobal.access.unlinked = roleGlobal.access.unlinked.map(unlinkedCard => {
        if (unlinkedCard.cardId === finalRoleCard.cardId) {
          foundInUnlinked = true;
          return { ...finalRoleCard };
        }
        return unlinkedCard;
      });
      if (!foundInUnlinked) {
        roleGlobal.access.unlinked.push(finalRoleCard);
      }
    }
    delete user.hasAccess;

    manageUsers.saveUser(user, true);
  };

  const checkDate = (startDate, endDate) => {
    const startMoment = startDate ? moment(startDate, format) : null;
    const endMoment = endDate ? moment(endDate, format) : null;

    return startMoment && endMoment && startMoment.isAfter(endMoment)
  }

  return <>
    <StyledCollapse onClick={() => setOpen(!open)}>
      <StyledCollapseLegend className="appId" collapsable="true">
        <StyledUserIcon>
          <div>
            <svg x="0" y="0" viewBox="0 -50 563.43 563.43" fill="#12263F">
              <path d="M280.79,314.559c83.266,0,150.803-67.538,150.803-150.803S364.055,13.415,280.79,13.415S129.987,80.953,129.987,163.756S197.524,314.559,280.79,314.559z M280.79,52.735c61.061,0,111.021,49.959,111.021,111.021S341.851,274.776,280.79,274.776s-111.021-49.959-111.021-111.021S219.728,52.735,280.79,52.735z" />
              <path d="M19.891,550.015h523.648c11.102,0,19.891-8.789,19.891-19.891c0-104.082-84.653-189.198-189.198-189.198H189.198C85.116,340.926,0,425.579,0,530.124C0,541.226,8.789,550.015,19.891,550.015z M189.198,380.708h185.034c75.864,0,138.313,56.436,148.028,129.524H41.17C50.884,437.607,113.334,380.708,189.198,380.708z" />
            </svg>
          </div>
        </StyledUserIcon>
        <StyledUserInfo>
          <p className="userName">{`${user.firstName} ${user.lastName}`} - <span>{`${user.email}`}</span></p>
          {user.hasAccess && (
            (roleCard && checkAccessDates(roleCard)) ?
              <p className="userPeriod">{getRoleText(roleGlobal.role)} - {getLabelAccess(roleGlobal.role, 'card')} - {getPeriod(roleCard.endDate)}</p>
              : (roleGlobal && checkAccessDates(roleGlobal)) ?
                <p className="userPeriod">{getRoleText(roleGlobal.role)} - {getLabelAccess(roleGlobal.role, 'role')} - {getPeriod(roleGlobal.endDate)}</p>
                : null
          )}
          {!user.hasAccess && <p className="userPeriod">{getRoleText(roleGlobal.role)} - {intl.formatMessage(messages.noAccess)}</p>}
        </StyledUserInfo>
      </StyledCollapseLegend>
      <Icon icon={open ? chevronDown : chevronRight} />
    </StyledCollapse>
    <StyledCollapseDiv open={open} $noUser={roleGlobal.role === 'superowner' || roleGlobal.role === 'owner' || roleGlobal.role === 'admin' || roleGlobal.typeAccess !== 'none_exept'}>
      {roleGlobal.role !== 'superowner' && roleGlobal.role !== 'owner' && roleGlobal.role !== 'admin' && roleGlobal.typeAccess === 'none_exept' ?
        <AvForm onSubmit={() => handleOnSubmit(tokenId === 0 ? 'unlinked': 'linked')}>
          <StyledFormContainer>
            <div className="elements">
              <StyledFormSelect>
                <select value={roleGlobal && roleGlobal.role} disabled={true}>
                  <option value=""></option>
                  <option value="superowner">{intl.formatMessage(messages.role_superowner)}</option>
                  <option value="owner">{intl.formatMessage(messages.role_owner)}</option>
                  <option value="admin">{intl.formatMessage(messages.role_admin)}</option>
                  <option value="editor">{intl.formatMessage(messages.role_editor)}</option>
                  <option value="redactor">{intl.formatMessage(messages.role_redactor)}</option>
                </select>
              </StyledFormSelect>
            </div>
            <div className="elements">
              <StyledFormDatePicker>
                <DatePicker
                  value={roleCard && roleCard.startDate ? moment(roleCard.startDate, format) : ""}
                  name={`startDate${index}`}
                  id="startDate"
                  label={intl.formatMessage(messages.startDate)}
                  locale={intl.locale === "fr" ? localeFr : localeEn}
                  onChange={(date, dateString) => handleDateChange(date, dateString, 'startDate')}
                  format={format}
                  size="large"
                  disabled={user.role === "admin" || user.role === "owner" || user.role === "superowner"}
                />

                <DatePicker
                  value={roleCard && roleCard.endDate ? moment(roleCard.endDate, format) : ""}
                  name={`endDate${index}`}
                  id="endDate"
                  label={intl.formatMessage(messages.endDate)}
                  locale={intl.locale === "fr" ? localeFr : localeEn}
                  onChange={(date, dateString) => handleDateChange(date, dateString, 'endDate')}
                  format={format}
                  size="large"
                  disabled={user.role === "admin" || user.role === "owner" || user.role === "superowner"}
                />
                <StyledHelpMessage error={dateError !== ''}>
                  {intl.formatMessage(messages.choicePeriodAccess)}
                </StyledHelpMessage>
                {dateError &&
                  <StyledDateErrorMessage>
                    {dateError}
                  </StyledDateErrorMessage>}
              </StyledFormDatePicker>
            </div>
            <div className="elements">
              {(currentUser.superadmin || currentUser.role === 'owner' || currentUser.role === "superowner" || currentUser.role === 'admin') &&
                <StyledFormButtonAction>
                  <BasicButton
                    type="submit"
                    label={getButtonText(user.hasAccess, 'update')}
                    icon={<Icon icon={getButtonIcon()} />}
                    color="green"
                    small
                    disabled={user.role === "admin" || user.role === "owner" || user.role === "superowner"}
                  />
                  {user.hasAccess && roleCard &&
                    <BasicButton
                      label={getButtonText(user.hasAccess, 'remove')}
                      icon={<Icon icon={getButtonIcon()} />}
                      color="red"
                      small
                      disabled={user.role === "admin" || user.role === "owner" || user.role === "superowner"}
                      onClick={handleRemoveCard}
                    />
                  }
                </StyledFormButtonAction>
              }
            </div>
          </StyledFormContainer>
        </AvForm>
      :
        <StyledNoFormContainer>
          <FormattedMessage
            {...messages.gotToManageUser}
            values={{ link: <a href={dns}>{intl.formatMessage(messages.manageusers)}</a> }}
          />
        </StyledNoFormContainer>
      }
    </StyledCollapseDiv>
  </>;
}

UsersShare.propTypes = {
  user: PropTypes.object.isRequired,
  appId: PropTypes.string.isRequired,
  index: PropTypes.number,
  manageUsers: PropTypes.object.isRequired,
  cardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tokenId: PropTypes.number,
};

export default connectStore({ manageUsers: ManageUsersModule })(UsersShare);

import PropTypes from 'prop-types'
import { BasicTextArea, PartOptions } from 'components'
import { useIntl } from 'react-intl'
import messages from './messages'

const SoundCloudForm = ({ onChange, part, partIndex, onFormChange }) => {
  const intl = useIntl()

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} values={part.options} onFormChange={onFormChange} />

      <BasicTextArea
        name="url"
        partIndex={partIndex}
        size="small"
        label={intl.formatMessage(messages.soundcloud)}
        helpMessage={intl.formatMessage(messages.soundcloudHelp)}
        value={part.url}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        required
        error={intl.formatMessage(messages.required)}
        validation={{ pattern: { value: /^https?:\/\/(soundcloud\.com|snd\.sc)\/(.*)$/ } }}
        link={part.url || ''}
      />
    </>
  )
}

SoundCloudForm.propTypes = {
  onChange: PropTypes.func,
  partIndex: PropTypes.number,
  part: PropTypes.object,
  onFormChange: PropTypes.func,
}

export default SoundCloudForm

import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicInput, ImgDropzone, PartOptions, BasicCheckbox } from 'components'
import { validationsPatterns, newEvent } from 'utils'

import StyleForm from './StyleForm'
import messages from './messages'

const ButtonHalfForm = ({ onChange, onPictureChange, part, partIndex, onCheckedChange, onFormChange }) => {
  const intl = useIntl()

  const handleDropChange = (droppedAccepted) => onPictureChange(droppedAccepted[0], partIndex)

  const handleChange = (e) => onChange(e, partIndex)

  const handleRemovePicture = () => onPictureChange(null, partIndex)

  return (
    <>
      <PartOptions
        hideTimer
        styleForm={
          <StyleForm
            partIndex={partIndex}
            onChange={(e) => {
              onChange(e, partIndex);
              onFormChange(true);
            }}
            currentStyle={part.style}
            text={part.text}
            file={part.file}
            url={part.url}
            setAsBackButton={part.return}
            msjBrowser={part.msjBrowser}
            onCheckedChange={(e) => onCheckedChange(e, partIndex)}
            onFormChange={onFormChange}
          />
        }
        onFormChange={onFormChange}
      />

      <ImgDropzone
        name={`file-${partIndex}`}
        error={intl.formatMessage(messages.required)}
        file={part.file}
        label={intl.formatMessage(messages.image)}
        helpMessage={intl.formatMessage(messages.imageHelp)}
        url={part.url}
        onChange={(e) => {
          handleDropChange(e);
          onFormChange(true);
        }}
        onDelete={handleRemovePicture}
        required
      />

      <BasicInput
        name="text"
        partIndex={partIndex}
        label={intl.formatMessage(messages.text)}
        helpMessage={intl.formatMessage(messages.textHelp)}
        placeholder={intl.formatMessage(messages.textPlaceholder)}
        value={part.text || ''}
        onChange={(e) => {
          handleChange(e);
          onFormChange(true);
        }}
      />

      {!part.return && (
        <BasicInput
          name="link"
          partIndex={partIndex}
          label={intl.formatMessage(messages.link)}
          helpMessage={intl.formatMessage(messages.linkHelp)}
          placeholder={intl.formatMessage(messages.linkPlaceholder)}
          value={part.link || ''}
          onChange={(e) => {
            handleChange(e);
            onFormChange(true);
          }}
          validation={{ pattern: validationsPatterns.urlExtended }}
          error={intl.formatMessage(messages.linkError)}
          link={part.link || ''}
        />
      )}

      <BasicCheckbox
        label={intl.formatMessage(messages.return)}
        helpMessage={intl.formatMessage(messages.returnHelp)}
        name="return"
        value={part.return || false}
        onChange={(e) => {
          onChange(newEvent('return', e.target.checked), partIndex);
          onFormChange(true);
        }}
      />
    </>
  )
}

ButtonHalfForm.propTypes = {
  onChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
  onPictureChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default ButtonHalfForm

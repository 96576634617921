import styled from 'styled-components'
import { getColor } from 'styles/functions'

export const StyledLegend = styled.legend`
  text-transform: uppercase;
  color: ${({ color }) => (color ? getColor(color) : '#406cb3')};
  font-weight: 600 !important;
  font-size: 14px !important;
`

export const StyledActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.position === 'top' ? 'space-between' : 'flex-end')};
  align-items: center;

  div {
    display: flex;
    flex-direction: row;

    button:last-of-type {
      margin-left: 10px;
    }
  }
`

export const StyledColorPicker = styled.div`
  margin: 10px 0;

  label {
    font-size: 14px;
  }

  .w-color-swatch {
    justify-content: flex-start;

    &.w-color-circle > div {
      height: 24px !important;
      width: 24px !important;
    }
  }

  .w-color-block {
    width: 100% !important;
  }

  .w-color-editable-input {
    display: none !important;
  }
`

export const StyledAnswersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px; 
  margin: 10px;
`;

export const StyledAnswersFullWidth = styled.div`
  grid-column: span 2;
    margin-top: -20px;
`;

export const StyledAnswersSemiWidth = styled.div`
  //padding: 20px;
  //text-align: center;
`;

export const StyledQuill = styled.div`
    margin: 10px 0;
    .ql-container.ql-snow {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #bebebe;
  }

  .ql-toolbar.ql-snow {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #bebebe;
  }

  .ql-editor {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 15px;
    line-height: 22.5px;
    padding: 14px;
  }
  .textarea-emoji-control {
    position: absolute;
    top: auto !important;
    bottom: 5px;
    right: 5px;
  }
`

export const StyledDurationContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  select {
    min-width: calc(100% + 13px);

    @media (max-width: 460px) {
      margin-top: -20px;
    }
  }
`

export const StyledDurationInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;

  button {
    margin-left: 23px;

    @media (max-width: 460px) {
      position: absolute;
      right: 0;
      bottom: 25px;
    }
  }
`

export const StyledDurationInputs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
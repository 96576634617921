import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AvGroup, AvField } from 'availity-reactstrap-validation'
import { BasicButton, BasicCheckbox, BasicInput, BasicInputDuration } from 'components'
import { useIntl } from 'react-intl'

import { Icon } from 'react-icons-kit'
import { trash } from 'react-icons-kit/feather/trash'
import { plus } from 'react-icons-kit/feather/plus'

import {
  StyledContainer,
  StyledInputContainer,
} from './styles'
import messages from './messages'
import { validationsPatterns } from '../../utils'

const BasicMultiInputArray = ({
  addLabel,
  errorMessage,
  handleOnChange,
  list,
  name,
  partIndex,
}) => {
  const [listState, setList] = useState(list || [{ response: '', redirect: '', unlockTime: {}, expiration: false }])

  const intl = useIntl()

  useEffect(() => {
    if (listState.length <= 0) {
      addInput()
    }
  }, [])

  const handleChangeList = (e) => {
    const { id, value, checked } = e.target;
    const newList = [...listState];
    const index = id.split('.')[1];
    const field = id.split('.')[2];
    
    if (index !== undefined && field) {
      const updatedItem = { ...newList[index] };
      
      if (field === 'unlockTime') {
        const subField = id.split('.')[3];
        updatedItem.unlockTime = {
          ...updatedItem.unlockTime,
          [subField]: subField === 'unit' && value === '' ? updatedItem.unlockTime[subField] : value
        };
      } else {
        updatedItem[field] = field === 'expiration' ? checked : value;
      }
      
      newList[index] = updatedItem;
    }
    
    setList(newList);
    if (handleOnChange) {
      handleOnChange({ target: { id: name, value: newList } }, partIndex);
    }
  };

  const addInput = (e) => {
    e.preventDefault()
    const newList = listState.concat([{ response: '', redirect: '', unlockTime: {}, expiration: false }])
    setList(newList)
    if (handleOnChange) {
      handleOnChange({ target: { id: name, value: newList } }, partIndex)
    }
  }

  const removeInput = (e, i) => {
    e.preventDefault()
    const newList = [...listState]
    newList.splice(i, 1)
    setList(newList)
    if (handleOnChange) {
      handleOnChange({ target: { id: name, value: newList } }, partIndex)
    }
  }

  const handleOnBlur = (e) => {
    e.target.value = e.target.value.trim()
    handleChangeList(e)
  }

  return (
    <StyledContainer>
      {listState.map((value, i) => (
        <div key={`url-input-${i}`} style={{ marginLeft: 10 }}>
          <StyledInputContainer>
            <span style={{ gridColumn: '1 span', textAlign: 'center', marginTop: '13px' }}>{i + 1}</span>
            <AvGroup>
              <AvField
                name={`${name}.${i}.response`}
                type="textarea"
                id={`${name}.${i}.response`}
                value={value.response}
                onChange={(e) => handleChangeList(e)}
                onBlur={handleOnBlur}
                required
                errorMessage={errorMessage}
                style={{
                  borderRadius: 8,
                  padding: '0.375rem 0.75rem',
                  fontSize: '1rem',
                  lineHeight: '1.5',
                  color: '#495057',
                  minHeight: '48px',
                  height: '48px',
                  borderColor: '#bebebe'
                }}
              />
            </AvGroup>
            <BasicInput
              name={`${name}.${i}.redirect`}
              type="text"
              id={`${name}.${i}.redirect`}
              value={value.redirect}
              onChange={(e) => handleChangeList(e)}
              onBlur={handleOnBlur}
              required
              error={intl.formatMessage(messages.redirectError)}
              validation={{ pattern: { value: validationsPatterns.urlExtended.value } }}
              style={{
                marginTop: -10
            }}
            />
            <div style={{ gridColumn: '1 span', marginLeft: '17px', marginTop: '8px' }}>
              <BasicButton onClick={(e) => removeInput(e, i)} icon={<Icon icon={trash} />} outlined round />
            </div>
          </StyledInputContainer>
          
          <div style={{ marginLeft: '35px' }}>
            <BasicCheckbox
              label={intl.formatMessage(messages.addExpiration)}
              name={`${name}.${i}.expiration`}
              id={`${name}.${i}.expiration`}
              helpMessage={intl.formatMessage(messages.addExpirationHelp)}
              checked={value.expiration}
              value={value.expiration}
              onChange={(e) => {
                handleChangeList(e)
              }}
            />
          </div>
          <div style={{ marginLeft: '35px' }}>
            {value.expiration && (
              <BasicInputDuration
                label={intl.formatMessage(messages.unlockTime)}
                helpMessage={intl.formatMessage(messages.unlockTimeHelp)}
                name={`${name}.${i}.unlockTime`}
                values={value.unlockTime ? value.unlockTime : { value: '', unit: ''}}
                required
              />
            )}
          </div>
        </div>
      ))}
      <div style={{ margin: '5px 0 20px 0' }}>
        <BasicButton
          color="green"
          medium
          onClick={addInput}
          label={addLabel || intl.formatMessage(messages.addLabel)}
          icon={<Icon icon={plus} />}
        />
      </div>
    </StyledContainer>
  )
}

BasicMultiInputArray.defaultProps = {
  errorMessage: '',
  handleOnChange: null,
  label: () => {
  },
  type: 'text',
}

BasicMultiInputArray.propTypes = {
  addLabel: PropTypes.string,
  children: PropTypes.node,
  errorMessage: PropTypes.string,
  handleOnChange: PropTypes.func,
  helpMessage: PropTypes.string,
  label: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      response: PropTypes.string,
      redirect: PropTypes.string,
      unlockTime: PropTypes.object,
      expiration: PropTypes.bool,
    })
  ),
  name: PropTypes.string,
  partIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  type: PropTypes.string,
}

export default BasicMultiInputArray

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Emoji from 'react-quill-emoji';
import { FormText, Label } from 'reactstrap'
import styled from 'styled-components'
import { AvInput } from 'availity-reactstrap-validation'
import { StyledQuill } from './styles'

const StyledLabel = styled(Label)`
  font-family: Nunito,serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
`

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = Emoji;
Quill.register({
  'formats/emoji': EmojiBlot,
  'modules/emoji-shortname': ShortNameEmoji,
  'modules/emoji-toolbar': ToolbarEmoji,
  'modules/emoji-textarea': TextAreaEmoji
}, true);

const QuillEditor = ({ value, placeholder, name, label, helpMessage }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [text, setText] = useState(value || '');

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
    'emoji-toolbar': false,
    'emoji-textarea': true,
    'emoji-shortname': false,
  };

  const formats = ['bold', 'italic', 'underline', 'strike', 'link', 'list', 'emoji'];

  return (
    <StyledQuill>
      <div className={`rich-text-editor ${isFocused ? 'focused' : ''}`}>
        {!!label && (
          <StyledLabel htmlFor={name}>
            {label}
          </StyledLabel>
        )}

        {helpMessage && <FormText>{helpMessage}</FormText>}

        <ReactQuill
          theme="snow"
          value={text}
          onChange={(content, delta, source, editor) => {
            setText(editor.getHTML());
          }}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <AvInput
          type="hidden"
          name={name}
          id={name}
          value={text}
        />
      </div>
    </StyledQuill>
  );
};

QuillEditor.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

export default QuillEditor;

import { Label, FormText } from 'reactstrap'
import { AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { BasicLink } from '../index'

const StyledInput = styled(AvGroup)`
  display: flex;
  flex-direction: ${(props) => (props.type === 'checkbox' ? 'row' : 'column')};
  ${(props) => props.type === 'checkbox' && 'align-items: baseline'};
  margin: 10px 0;

  &[type='radio'] {
    background-color: red;
  }

  label {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;

    @media (max-width: 568px) {
        margin-top: 25px!important;
    }
    @media (min-width: 770px) and (max-width: 836px) {
        margin-top: 25px!important;
    }
  }

  input,
  input:is(:focus, :active),
  select,
  select:is(:focus, :active),
  textarea,
  textarea:is(:focus, :active) {
    height: ${({ type }) => (type === 'checkbox' ? 'fit-content' : '48px')};
    border-radius: 10px !important;
    border: 1px solid #bebebe;
    padding: 13px;
    font-size: 15px;
    margin-bottom: 0;

    &::placeholder {
      color: #bebebe;
    }
  }
`

const placeholders = {
  url: 'ex: https://google.com',
}

const BasicInput = ({
  accept,
  autoComplete,
  children,
  disabled,
  error,
  helpMessage,
  inputClassName,
  label,
  max,
  min,
  name,
  onChange,
  onBlur,
  partIndex,
  placeholder,
  readOnly,
  required,
  style,
  type,
  validation,
  validationOneOf,
  value,
  link,
	linkMargin
}) => {
  const handleChange = (e) => {
    onChange(e, partIndex)
  }

  const partName = partIndex ? `${name}-${partIndex}` : name

  const validate = validation || {}

  if (validationOneOf) {
    validate.async = (val, ctx, input, cb) => {
      cb(validationOneOf.includes(val))
    }
  }

  const labelAfterInput = type === 'checkbox' || type === 'radio'

  const handleOnBlur = (e) => {
    onBlur(e)
    e.target.value = e.target.value.trim()
    handleChange(e)
  }

  return (
    <>
      <StyledInput
        style={type === 'hidden' ? { margin: 0, height: 0, visibility: 'hidden', padding: 0 } : {}}
        type={type}
      >
        {!!label && !labelAfterInput && (
          <Label htmlFor={partName}>
            {label}
            {required && <span className="invalid-feedback">&nbsp;*</span>}
          </Label>
        )}
        {!labelAfterInput && helpMessage && <FormText>{helpMessage}</FormText>}

        <div style={{display: "flex"}}>
          <AvInput
            style={style}
            type={type}
            name={partName}
            id={name}
            value={value}
            placeholder={placeholder || placeholders[type] || ''}
            onChange={handleChange}
            required={required}
            validate={validate}
            disabled={disabled}
            readOnly={readOnly}
            onBlur={handleOnBlur}
            accept={accept}
            className={inputClassName}
            min={min}
            max={max}
            autoComplete={autoComplete}
          >
            {children}
          </AvInput>

          {link && (<BasicLink link={link} margin={linkMargin || 10} />)}
        </div>


        {!!label && labelAfterInput && <Label htmlFor={partName}>{label}</Label>}
        {error && <AvFeedback>{error}</AvFeedback>}
      </StyledInput>

      {labelAfterInput && helpMessage && <FormText style={{ marginTop: '-5px' }}>{helpMessage}</FormText>}
    </>
  )
}

BasicInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  type: 'text',
  value: '',
  name: '',
}

BasicInput.propTypes = {
  accept: PropTypes.string,
  autoComplete: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helpMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  partIndex: PropTypes.any,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  style: PropTypes.any,
  type: PropTypes.string,
  inputClassName: PropTypes.string,
  validation: PropTypes.any,
  validationOneOf: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.any,
  link: PropTypes.string,
	linkMargin: PropTypes.number,
}

export default BasicInput

import { BasicButton } from 'components';
import messages from './message'
import { FormattedMessage, useIntl } from "react-intl";

import { connectStore } from 'redux-box';
import { storeModule as ManageUsersModule } from 'store/manageUsers';

import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit'
import { trash } from 'react-icons-kit/feather/trash'
import { chevronRight } from 'react-icons-kit/feather/chevronRight'
import { chevronDown } from 'react-icons-kit/feather/chevronDown'
import { plusCircle } from 'react-icons-kit/feather/plusCircle';
import { lock } from 'react-icons-kit/feather/lock';
import {
  StyledCollapse,
  StyledCollapseLegend,
  StyledCollapseDiv,
  StyledUserIcon,
  StyledUserInfo,
  StyledFormContainer,
  StyledFormSelect,
  StyledFormDatePicker,
  StyledFormButtonAction,
  StyledNoFormContainer,
  StyledHelpMessage, StyledDateErrorMessage
} from "./styles";

import { useEffect, useState } from "react";
import { DatePicker } from "antd";
import moment from "moment/moment";
import localeFr from "antd/lib/date-picker/locale/fr_FR";
import localeEn from "antd/lib/date-picker/locale/en_US";
import { AvForm } from "availity-reactstrap-validation";
import { ADMIN_SHORT_URL, DATEPICKER_OPTIONS } from "../../utils/consts";
import { db } from "../../services/rsf";
import { checkAccessDates } from "../../utils/share";

const UsersShareFolder = ({ user, folderId, appId, index, manageUsers}) => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const format = DATEPICKER_OPTIONS.dateFormat[intl.locale];
  const [cards, setCards] = useState([]);
  const app = user.apps.find(app => app.appId === appId);
  const roleGlobal  = app ? app.roles[0] : [];
  const [roleFolder, setRoleFolder] = useState(null);
  const currentUser = manageUsers.profile;
  const dns = `${ADMIN_SHORT_URL}/${appId}/manageusers`;
  const [dateError, setDateError] = useState('');

  useEffect(() => {
    if(roleGlobal.access.folders) {
      const found = roleGlobal.access.folders.find(folder => folder.folderId === folderId);
      if (found) {
        setRoleFolder(found);
      }
    }

    const fetchData = async () => {
      const fetchCards = async () => {
        const cardsSnap = await db.collection(`appdata/${appId}/cards`).get();
        const cardsDocs = cardsSnap.docs.map(doc => doc.data());
        if (cardsDocs.length > 0) {
          return cardsDocs;
        }
        return [];
      };

      const cards = await fetchCards();

      setCards(cards);
    };
    fetchData();
  }, [roleGlobal, folderId]);

  const getButtonText = (hasAccess, mode) => {
    if(roleGlobal.role !== 'superowner' && roleGlobal.role !== 'owner' && roleGlobal.role !== 'admin' && hasAccess) {
      if (mode === 'remove') {
        return intl.formatMessage(messages.removeAccess);
      } else {
        return intl.formatMessage(messages.editAccess);
      }
    } else {
      return intl.formatMessage(messages.addAccess);
    }
  };

  const getButtonIcon = () => {
    if((currentUser.role === 'superowner' || currentUser.role === 'owner' || currentUser.role === 'admin') && roleGlobal.role !== 'owner' && roleGlobal.role !== 'admin') {
      if(roleGlobal.typeAccess === 'none_exept' && roleGlobal.hasAccess) {
         return trash;
      }
      if (roleGlobal.typeAccess === 'none_exept' && !roleGlobal.hasAccess) {
        return plusCircle;
      }
    }

    return lock;
  };

  const getRoleText = (role) => {
    switch (role) {
      case 'superowner':
        return intl.formatMessage(messages.role_superowner);
      case 'owner':
        return intl.formatMessage(messages.role_owner);
      case 'admin':
        return intl.formatMessage(messages.role_admin);
      case 'editor':
        return intl.formatMessage(messages.role_editor);
      case 'redactor':
        return intl.formatMessage(messages.role_redactor);
      default:
        return intl.formatMessage(messages.role_viewer);
    }
  };

  const getLabelAccess = (role, typeAccess) => {
    if(role === 'superowner' || role === 'owner' || role === 'admin') {
      return intl.formatMessage(messages.allAccess);
    }

    if(typeAccess === 'folder') {
      return intl.formatMessage(messages.customAccess);
    } else {
      return intl.formatMessage(messages.hasAccess);
    }
  };

  const getPeriod = (end) => {
    if(end && end !== '') {
      return <FormattedMessage
        {...messages.periodToEnd}
        values={{ date: end }}
      />;
    }
    return intl.formatMessage(messages.noPeriod);
  }

  const handleDateChange = (date, dateString, type) => {
    const startDate = type === 'startDate' ? dateString : roleFolder && roleFolder.startDate ? roleFolder.startDate : '';
    const endDate = type === 'endDate' ? dateString : roleFolder && roleFolder.endDate ? roleFolder.endDate : '';

    if(checkDate(startDate, endDate)) {
      setDateError('La date de début doit être antérieure à la date de fin');
      return;
    } else {
      setDateError('')
    }

    const updatedRole = {
      ...roleFolder,
      folderId: folderId,
      startDate: startDate,
      endDate: endDate,
    };

    setRoleFolder(updatedRole);
  };

  const handleRemoveFolder = () => {
    roleGlobal.access.folders = roleGlobal.access.folders.filter(folder => folder.folderId !== folderId);
    delete user.hasAccess;

    const cardIdsToRemove = cards
      .filter(card => card.folders && card.folders.includes(folderId))
      .map(card => card.cardId);

    roleGlobal.access.linked = roleGlobal.access.linked.filter(card => !cardIdsToRemove.includes(card.cardId));
    roleGlobal.access.unlinked = roleGlobal.access.unlinked.filter(card => !cardIdsToRemove.includes(card.cardId));

    manageUsers.saveUser(user, true);
  }

  const handleOnSubmit = () => {
    if(dateError !== '') {
      return;
    }

    const finalRoleFolder = {
      ...roleFolder,
      folderId: folderId,
    };

    let foundInFolder = false;
    roleGlobal.access.folders = roleGlobal.access.folders.map(folder => {
      if (folder.folderId === finalRoleFolder.folderId) {
        foundInFolder = true;
        return { ...finalRoleFolder };
      }
      return folder;
    });
    if (!foundInFolder) {
      roleGlobal.access.folders.push(finalRoleFolder);
    }

    cards.forEach(card => {
      if (card.folders && card.folders.includes(folderId)) {
        const cardEntry = { cardId: card.cardId, startDate: finalRoleFolder.startDate, endDate: finalRoleFolder.endDate };

        if (card.tokenId !== 0) {
          const existingEntry = roleGlobal.access.linked.find(c => c.cardId === card.cardId);
          if (existingEntry) {
            existingEntry.startDate = cardEntry.startDate;
            existingEntry.endDate = cardEntry.endDate;
          } else {
            roleGlobal.access.linked.push(cardEntry);
          }
        } else {
          const existingEntry = roleGlobal.access.unlinked.find(c => c.cardId === card.cardId);
          if (existingEntry) {
            existingEntry.startDate = cardEntry.startDate;
            existingEntry.endDate = cardEntry.endDate;
          } else {
            roleGlobal.access.unlinked.push(cardEntry);
          }
        }
      }
    });
    delete user.hasAccess;

    manageUsers.saveUser(user, true);
  };

  const checkDate = (startDate, endDate) => {
    const startMoment = startDate ? moment(startDate, format) : null;
    const endMoment = endDate ? moment(endDate, format) : null;

    return startMoment && endMoment && startMoment.isAfter(endMoment)
  }

  return <>
    <StyledCollapse onClick={() => setOpen(!open)}>
      <StyledCollapseLegend className="appId" collapsable="true">
        <StyledUserIcon>
          <div>
            <svg x="0" y="0" viewBox="0 -50 563.43 563.43" fill="#12263F">
              <path d="M280.79,314.559c83.266,0,150.803-67.538,150.803-150.803S364.055,13.415,280.79,13.415S129.987,80.953,129.987,163.756S197.524,314.559,280.79,314.559z M280.79,52.735c61.061,0,111.021,49.959,111.021,111.021S341.851,274.776,280.79,274.776s-111.021-49.959-111.021-111.021S219.728,52.735,280.79,52.735z" />
              <path d="M19.891,550.015h523.648c11.102,0,19.891-8.789,19.891-19.891c0-104.082-84.653-189.198-189.198-189.198H189.198C85.116,340.926,0,425.579,0,530.124C0,541.226,8.789,550.015,19.891,550.015z M189.198,380.708h185.034c75.864,0,138.313,56.436,148.028,129.524H41.17C50.884,437.607,113.334,380.708,189.198,380.708z" />
            </svg>
          </div>
        </StyledUserIcon>
        <StyledUserInfo>
          <p className="userName">{`${user.firstName} ${user.lastName}`} - <span>{`${user.email}`}</span></p>
          {user.hasAccess && (
            (roleFolder && checkAccessDates(roleFolder)) ?
              <p className="userPeriod">{getRoleText(roleGlobal.role)} - {getLabelAccess(roleGlobal.role, 'folder')} - {getPeriod(roleFolder.endDate)}</p>
              : (roleGlobal && checkAccessDates(roleGlobal)) ?
                <p className="userPeriod">{getRoleText(roleGlobal.role)} - {getLabelAccess(roleGlobal.role, 'role')} - {getPeriod(roleGlobal.endDate)}</p>
                : null
          )}

          {!user.hasAccess && <p className="userPeriod">{getRoleText(roleGlobal.role)} - {intl.formatMessage(messages.noAccess)}</p>}
        </StyledUserInfo>

      </StyledCollapseLegend>
      <Icon icon={open ? chevronDown : chevronRight} />
    </StyledCollapse>
    <StyledCollapseDiv open={open} $noUser={roleGlobal.role === 'superowner' || roleGlobal.role === 'owner' || roleGlobal.role === 'admin' || roleGlobal.typeAccess !== 'none_exept'}>
      {roleGlobal.role !== 'superowner' && roleGlobal.role !== 'owner' && roleGlobal.role !== 'admin' && roleGlobal.typeAccess === 'none_exept' ?
        <AvForm onSubmit={handleOnSubmit}>
          <StyledFormContainer>
            <div className="elements">
              <StyledFormSelect>
                <select value={roleGlobal && roleGlobal.role} disabled={true}>
                  <option value=""></option>
                  <option value="superowner">{intl.formatMessage(messages.role_superowner)}</option>
                  <option value="owner">{intl.formatMessage(messages.role_owner)}</option>
                  <option value="admin">{intl.formatMessage(messages.role_admin)}</option>
                  <option value="editor">{intl.formatMessage(messages.role_editor)}</option>
                  <option value="redactor">{intl.formatMessage(messages.role_redactor)}</option>
                </select>
              </StyledFormSelect>
            </div>
            <div className="elements">
              <StyledFormDatePicker>
                <DatePicker
                  value={roleFolder && roleFolder.startDate ? moment(roleFolder.startDate, format) : ''}
                  name={`startDate${index}`}
                  id="startDate"
                  label={intl.formatMessage(messages.startDate)}
                  locale={intl.locale === 'fr' ? localeFr : localeEn}
                  onChange={(date, dateString) => handleDateChange(date, dateString, 'startDate')}
                  format={format}
                  size="large"
                  disabled={user.role === 'admin' || user.role === 'owner' || user.role === 'superowner'}
                />

                <DatePicker
                  value={roleFolder && roleFolder.endDate ? moment(roleFolder.endDate, format) : ''}
                  name={`endDate${index}`}
                  id="endDate"
                  label={intl.formatMessage(messages.endDate)}
                  locale={intl.locale === 'fr' ? localeFr : localeEn}
                  onChange={(date, dateString) => handleDateChange(date, dateString, 'endDate')}
                  format={format}
                  size="large"
                  disabled={user.role === 'admin' || user.role === 'owner' || user.role === 'superowner'}
                />
                <StyledHelpMessage error={dateError !== ''}>
                  {intl.formatMessage(messages.choicePeriodAccess)}
                </StyledHelpMessage>
                {dateError &&
                  <StyledDateErrorMessage>
                    {dateError}
                  </StyledDateErrorMessage>}
              </StyledFormDatePicker>
            </div>
            <div className="elements">
              {(currentUser.superadmin || currentUser.role === 'owner' || currentUser.role === "superowner" || currentUser.role === 'admin') &&
                <StyledFormButtonAction>
                  <BasicButton
                    type="submit"
                    label={getButtonText(user.hasAccess, 'update')}
                    icon={<Icon icon={getButtonIcon()} />}
                    color="green"
                    small
                    disabled={user.role === 'admin' || user.role === 'owner' || user.role === 'superowner'}
                  />
                  {user.hasAccess && roleFolder &&
                    <BasicButton
                      label={getButtonText(user.hasAccess, 'remove')}
                      icon={<Icon icon={getButtonIcon()} />}
                      color="red"
                      small
                      disabled={user.role === 'admin' || user.role === 'owner' || user.role === 'superowner'}
                      onClick={handleRemoveFolder}
                    />
                  }
                </StyledFormButtonAction>
              }
            </div>
          </StyledFormContainer>
        </AvForm>
        :
        <StyledNoFormContainer>
          <FormattedMessage
            {...messages.gotToManageUser}
            values={{ link: <a href={dns}>{intl.formatMessage(messages.manageusers)}</a> }}
          />
        </StyledNoFormContainer>
      }
    </StyledCollapseDiv>
  </>;
}

UsersShareFolder.propTypes = {
  user: PropTypes.object.isRequired,
  appId: PropTypes.string.isRequired,
  index: PropTypes.number,
  manageUsers: PropTypes.object.isRequired,
  folderId: PropTypes.string,
};

export default connectStore({ manageUsers: ManageUsersModule })(UsersShareFolder);

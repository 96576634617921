import { defineMessages } from 'react-intl'

const scope = 'app.containers.PageDispatcher'

export default defineMessages({
  addExpiration: { id: `${scope}.addExpiration` },
  addExpirationHelp: { id: `${scope}.addExpirationHelp` },
  unlockTime: { id: `${scope}.unlockTime` },
  unlockTimeHelp: { id: `${scope}.unlockTimeHelp` },
  days: { id: `${scope}.days` },
  hours: { id: `${scope}.hours` },
  minutes: { id: `${scope}.minutes` },
  timeUnit: { id: `${scope}.timeUnit` },
  unitError: { id: `${scope}.unitError` },
  unlockTimeError: { id: `${scope}.unlockTimeError` },
  redirectError: { id: `${scope}.redirectError` },
})

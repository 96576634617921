import { defineMessages } from 'react-intl'

const scope = 'app.containers.PageDispatcher'

export default defineMessages({
  metaTitle: { id: `${scope}.metaTitle` },
  keyCode: { id: `${scope}.keyCode` },
  intro: { id: `${scope}.intro` },
  myDispatchers: { id: `${scope}.myDispatchers` },
  addDispatcher: { id: `${scope}.addDispatcher` },
  newDispatcher: { id: `${scope}.newDispatcher` },
  name: { id: `${scope}.name` },
  nameError: { id: `${scope}.nameError` },
  nameHelp: { id: `${scope}.nameHelp` },
  namePlaceholder: { id: `${scope}.namePlaceholder` },
  content: { id: `${scope}.content` },
  contentHelp: { id: `${scope}.contentHelp` },
  title: { id: `${scope}.title` },
  titleError: { id: `${scope}.titleError` },
  titleHelp: { id: `${scope}.titleHelp` },
  titlePlaceholder: { id: `${scope}.titlePlaceholder` },
  subtitle: { id: `${scope}.subtitle` },
  subtitleError: { id: `${scope}.subtitleError` },
  subtitleHelp: { id: `${scope}.subtitleHelp` },
  subtitlePlaceholder: { id: `${scope}.subtitlePlaceholder` },
  textBefore: { id: `${scope}.textBefore` },
  textBeforeHelp: { id: `${scope}.textBeforeHelp` },
  textBeforePlaceholder: { id: `${scope}.textBeforePlaceholder` },
  typeButton: { id: `${scope}.typeButton` },
  typeButtonError: { id: `${scope}.typeButtonError` },
  typeButtonHelp: { id: `${scope}.typeButtonHelp` },
  typeButtonPlaceholder: { id: `${scope}.typeButtonPlaceholder` },
  textAfter: { id: `${scope}.textAfter` },
  textAfterHelp: { id: `${scope}.textAfterHelp` },
  textAfterPlaceholder: { id: `${scope}.textAfterPlaceholder` },
  appearance: { id: `${scope}.appearance` },
  color: { id: `${scope}.color` },
  colorHelp: { id: `${scope}.colorHelp` },
  frame: { id: `${scope}.frame` },
  frameHelp: { id: `${scope}.frameHelp` },
  answers: { id: `${scope}.answers` },
  answerErrors: { id: `${scope}.answerErrors` },
  answersInfo: { id: `${scope}.answersInfo` },
  acceptedAnswers: { id: `${scope}.acceptedAnswers` },
  redirect: { id: `${scope}.redirect` },
  answersHelp: { id: `${scope}.answersHelp` },
  refused: { id: `${scope}.refused` },
  refusedHelp: { id: `${scope}.refusedHelp` },
  refusedError: { id: `${scope}.refusedError` },
  refusedPlaceholder: { id: `${scope}.refusedPlaceholder` },
  unlockTime: { id: `${scope}.unlockTime` },
  unlockTimeHelp: { id: `${scope}.unlockTimeHelp` },
  unlockTimeError: { id: `${scope}.unlockTimeError` },
  days: { id: `${scope}.days` },
  hours: { id: `${scope}.hours` },
  minutes: { id: `${scope}.minutes` },
  timeUnit: { id: `${scope}.timeUnit` },
  page: { id: `${scope}.page` },
  relatedCards: { id: `${scope}.relatedCards` },
  noRelatedCards: { id: `${scope}.noRelatedCards` },
  delete: { id: `${scope}.delete` },
  modalText: { id: `${scope}.modalText` },
  modalTitle: { id: `${scope}.modalTitle` },
  viewAnswers: { id: `${scope}.viewAnswers` },
  viewExpiration: { id: `${scope}.viewExpiration` },
  viewTimer: { id: `${scope}.viewTimer` },
  addAnswers: { id: `${scope}.addAnswers` },
  cancel: { id: `${scope}.cancel` },
  edit: { id: `${scope}.edit` },
  id: { id: `${scope}.id` },
  preview: { id: `${scope}.preview` },
  save: { id: `${scope}.save` },
  view: { id: `${scope}.view` },
  viewMore: { id: `${scope}.viewMore` },
  viewLess: { id: `${scope}.viewLess` },
})

import PropTypes from 'prop-types'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { PartOptions, BasicInput, BasicButton, BasicInfo } from 'components'
import { validationsPatterns, newEvent } from 'utils'
import { rteAppId } from 'utils/routes'

import { Icon } from 'react-icons-kit'
import { externalLink } from 'react-icons-kit/feather/externalLink'

import LockSelect from './LockSelect'
import LockModal from './LockModal'
import messages from './messages'

/* 
  what's not shared between lock, lockPage and lockCancel:

  - lockPage -
  ... input to get url redirect ...
*/
const LocksForm = ({ appId, onChange, part, partIndex, toggleDisable, onFormChange }) => {
  const [open, setOpen] = useState(false)

  const intl = useIntl()

  const toggleModal = () => {
    setOpen(!open)
    toggleDisable()
  }

  const handleOnSelect = (e) => {
    e.preventDefault()
    onChange(e, partIndex)
    onFormChange(true);
  }

  const handleOnSave = (id) => {
    onChange(newEvent('id', id), partIndex)
    toggleModal()
  }

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} values={part.options} onFormChange={onFormChange} />

      <LockSelect id={part.id} onSelect={handleOnSelect} partIndex={partIndex} />

      {part.type === 'LockPage' && (
        <BasicInput
          type="text"
          name="url"
          label={intl.formatMessage(messages.url)}
          helpMessage={intl.formatMessage(messages.urlHelp)}
          partIndex={partIndex}
          value={part.url || ''}
          required
          validation={{ pattern: validationsPatterns.url }}
          onChange={(e) => onChange(e, partIndex)}
          error={intl.formatMessage(messages.urlError)}
          link={part.url || ''}
        />
      )}

      <LockModal
        appId={appId}
        onSave={handleOnSave}
        id={part.id}
        toggleDisable={toggleDisable}
        open={open}
        setOpen={() => setOpen(!open)}
      />

      <BasicInfo
        text={intl.formatMessage(messages[`info${part.type}`])}
        children={
          <BasicButton
            label={intl.formatMessage(messages.tutorial)}
            icon={<Icon icon={externalLink} />}
            isLink
            href={rteAppId(appId, 'TUTORIAL')}
            target="_blank"
            linkstyle
            small
          />
        }
      />
    </>
  )
}

LocksForm.propTypes = {
  appId: PropTypes.string,
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  toggleDisable: PropTypes.func,
  onFormChange: PropTypes.func,
}

export default LocksForm

import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { BasicInput, BasicWarning } from 'components'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as DispatcherModule } from 'store/dispatchers'

import messages from './messages'

const DispatcherSelect = ({ dispatchers, partIndex, onSelect, id }) => {
  const [dispatcher, setDispatcher] = useState()

  const intl = useIntl()

  useEffect(() => {
    if (!id) {
      setDispatcher('')
    } else {
      const data = dispatchers.getDispatcherById(id)
      setDispatcher(data)
    }
  }, [id])

  return (
    <>
      <BasicInput
        type="select"
        name="id"
        value={!dispatcher && !!id ? '' : id}
        onChange={onSelect}
        required
        label={intl.formatMessage(messages.select)}
        error={intl.formatMessage(messages.error)}
        partIndex={partIndex}
      >
        <option value="" disabled>
          {intl.formatMessage(messages.select)}
        </option>

        {Object.values(dispatchers.list).map(
          (dispatcher, i) =>
            dispatcher.name &&
            dispatcher.name.length > 0 && (
              <option value={dispatcher.id} key={i}>
                {dispatcher.name}
              </option>
            )
        )}
      </BasicInput>

      {!dispatcher && id && (
        <div style={{ margin: '-10px 0 20px 0' }}>
          <BasicWarning text={intl.formatMessage(messages.deletedDispatcher)} />
        </div>
      )}
    </>
  )
}

DispatcherSelect.propTypes = {
  locks: PropTypes.any,
  onSelect: PropTypes.func,
  partIndex: PropTypes.number,
  id: PropTypes.string,
}

export default connectStore({ dispatchers: DispatcherModule })(DispatcherSelect)

import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import BasicButton from 'components/BasicButton'
import { useIntl } from 'react-intl'

import { trash } from 'react-icons-kit/feather/trash'
import { plus } from 'react-icons-kit/feather/plus'
import { Icon } from 'react-icons-kit'

import messages from './messages'
import { StyledContainer, StyledRow } from './styles'
import { BasicInput } from '../index'

const defaultValueFromConfig = (inputConfig) => {
  const intialData = {}

  inputConfig.forEach((input) => {
    intialData[input.inputName] = input.defaultValue
  })

  return intialData
}

// is not connected to "part" and "state"
// to use only outside of card edit form
const BasicInputMatriceArray = ({ initialData, inputsConfig, name }) => {
  const [dataArray, setDataArray] = useState(
    initialData && Object.values(initialData).length ? initialData : [defaultValueFromConfig(inputsConfig)]
  )
  const intl = useIntl()

  const addRow = (e) => {
    e.preventDefault()
    setDataArray((prevData) => [...prevData, defaultValueFromConfig(inputsConfig)])
  }
  const removeRow = (e) => {
    e.preventDefault()
    const data = [...dataArray]
    data.pop()
    setDataArray(data)
  }

  return (
    <StyledContainer>
      {Object.values(dataArray).map((row, i) => (
        <Fragment key={i}>
          {row && (
            <StyledRow>
              <span className="optionNum">{i + 1}</span>
              {inputsConfig.map((input) => (
                <BasicInput
                  name={`${name}.${i}.${input.inputName}`}
                  type="text"
                  placeholder={input.inputLabel}
                  label={i === 0 ? input.inputLabel : null}
                  required={input.required}
                  className="optionInput"
                />
              ))}

              {i === dataArray.length - 1 && (
                <BasicButton
                  className="optionDelete"
                  onClick={removeRow}
                  icon={<Icon icon={trash} />}
                  outlined
                  round
                />
              )}
            </StyledRow>
          )}
        </Fragment>
      ))}
      <BasicButton
        color="green"
        onClick={addRow}
        icon={<Icon icon={plus} />}
        label={intl.formatMessage(messages.addOne)}
        medium
      />
    </StyledContainer>
  )
}

BasicInputMatriceArray.propTypes = {
  name: PropTypes.string,
  initialData: PropTypes.array,
  inputsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      inputName: PropTypes.string.isRequired,
      inputLabel: PropTypes.string.isRequired,
      defaultValue: PropTypes.string.isRequired,
    })
  ),
}

export default BasicInputMatriceArray

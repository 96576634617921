import PropTypes from 'prop-types'
import { LocksForm, DispatchersForm } from 'components'

const Locks = ({ part, partIndex, app, onChange, toggleDisable, toggleModal, onFormChange }) => {
  return (
    <>
      {(part.type === 'Lock' || part.type === 'LockCancel' || part.type === 'LockPage') && (
        <LocksForm
          appId={app.id}
          onChange={onChange}
          part={part}
          partIndex={partIndex}
          toggleDisable={toggleDisable}
          toggleModal={toggleModal}
          onFormChange={onFormChange}
        />
      )}
      {(part.type === 'Dispatcher') && (
        <DispatchersForm
          appId={app.id}
          onChange={onChange}
          part={part}
          partIndex={partIndex}
          toggleDisable={toggleDisable}
          toggleModal={toggleModal}
          onFormChange={onFormChange}
        />
      )}
    </>
  )
}

Locks.propTypes = {
  app: PropTypes.object,
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  toggleDisable: PropTypes.func,
  toggleModal: PropTypes.func,
  onFormChange: PropTypes.func,
}

export default Locks

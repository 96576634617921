import styled from 'styled-components'
import { getColor } from 'styles/functions'

export const StyledContainer = styled.div`
  background-color: ${({ $background }) => $background};
  padding: 20px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: ${({ $border }) => ($border ? '3px solid rgb(52, 176, 118)' : '')};
  border-right: ${({ $border }) => ($border ? '3px solid rgb(52, 176, 118)' : '')};
    width: 320px;

  h2 {
    margin: 0 0 5px 0;
    font-family: 'Nunito', sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.7px;
  }
  h3 {
      margin: 0 0 5px 0;
      font-family: 'Nunito', sans-serif;
      font-size: 26px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.7px;
      margin-top: 10px;
  }
`

export const StyledButton = styled.button`
    display: block;
    background-color: rgb(52, 176, 118);
    color: white;
    padding: 0.75rem;
    width: 100%;
    margin: 10px auto auto;
    border: 0.125rem solid rgb(52, 176, 118);
    border-radius: 0.75rem;
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 0.1) 0s;
    outline: none;
    cursor: pointer;

    &:hover {
        background-color: transparent;
        border-color: rgb(52, 176, 118);
        color: rgb(52, 176, 118);
    }
`

export const StyledInput = styled.input`
  min-height: 3rem;
  border-radius: 0.375rem;
  background-color: white;
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 0px 1.25rem;
  color: black;
  padding: 10px 40px;
  width: 220px;
  border: 1px solid #ccc;
`

export const StyledErrorLabel = styled.p`
  color: #B51026;
  margin: 10px;
  font-weight: 700;
`

export const StyledInputContainer = styled.div`
  position: relative;

  i {
    position: absolute;

    &.lock {
      left: 33px;
      top: 10px;

      svg {
        color: rgb(204, 204, 204);
      }
    }

    &.state {
      right: 33px;
      top: 12px;

      svg {
        color: ${({ error, valid }) => getColor((valid && 'green') || (error && 'pink'))};
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
`

export const StyledInputGoEmojiContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
i {
  position: absolute;

  &.lock {
    left: 15px;
    top: 10px;

    svg {
        color: rgb(204, 204, 204);
    }
  }

  &.state {
    right: 33px;
    top: 12px;

    svg {
        color: ${({ error, valid }) => getColor((valid && 'green') || (error && 'pink'))};
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const StyledGoInput = styled.input`
    min-height: 3rem;
    border-radius: 0.375rem;
    background-color: white;
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0 5px 0 0;
    color: black;
    padding: 10px 40px;
    width: 220px;
    border: 1px solid #ccc;
`;

export const StyledGoButton = styled.button`
    background-color: rgb(52, 176, 118);
    color: white;
    border: 1px solid rgb(52, 176, 118);
    border-radius: 0.375rem;
    box-shadow: none;
    margin: 0;
    transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 0.1) 0s;
    outline: none;
    cursor: pointer;
    width: 48px;
    min-height: 3rem;

    &:hover {
        background-color: transparent;
        border-color: rgb(52, 176, 118);
        color: rgb(52, 176, 118);
    }
`;

export const StyledEmojiButton = styled.button`
    background-color: rgb(52, 176, 118);
    color: white;
    border: 1px solid rgb(52, 176, 118);
    border-radius: 0.375rem;
    box-shadow: none;
    margin: 0;
    transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 0.1) 0s;
    outline: none;
    cursor: pointer;
    width: 48px;
    min-height: 3rem;

    &:hover {
        background-color: transparent;
        border-color: rgb(52, 176, 118);
        color: rgb(52, 176, 118);
    }
`;